import '../Step1.scss';
import React, { memo } from 'react';
import { CalendarDesiredDate } from './CalendarDesiredDate/CalendarDesiredDate';
import { CalendarAvailableDate } from './CalendarAvailableDate/CalendarAvailableDate';
import { RegistrationListFieldsType } from '../../../../api/fields-api';
import { InitialStateType } from '../../../../redux/registration-reducer';
import { DataOfClientsType } from '../../../../api/registration-api';
import { baseURL_WIDGET } from '../../../../utils/BaseURL';

type FormStep1Type = {
  control: any;
  fields: RegistrationListFieldsType;
  registrationData: InitialStateType;
  branchId: number;
    isSelectedCalendarType: boolean;
  setSelectedDesiredDate: (date: string) => void;
  setDesiredDateCallback: (value: boolean) => void;
  prevWeek: (currBranchId: number, week: number, reasonId?: string) => void;
  nextWeek: (currBranchId: number, week: number, reasonId?: string) => void;
  registration: DataOfClientsType;
  setSelectedDesiredDateCallback: (value: string) => void;
  generalDate: Date | undefined;
  setGeneralDate: (value: Date | undefined) => void;
  valueTime: string;
  setTimeDefaultValueCallback: (value: { value: string; label: string }) => void;
  selectedTimeDesired: { value: string; label: string };
  setChoicesTime: (choices: { value: string; label: string }[]) => void;
  setSelectedTime: (time: { value: string; label: string }) => void;
  setSelectedDate: (value: string) => void;
  setFreeDateCallback: (value: boolean) => void;
  isCollapsedCalendar: boolean;
  setIsDesiredDate: (value: boolean) => void;
  setValueCallback: (date: string, start: string) => void;
  setSelectedAvailableDate: (date: string) => void;
  oChangeStatusCalendar: () => void;
  onChangeCalendarType: () => void;
  setClassNameBtn: () => string | undefined;
  setDisabledBtn: () => boolean | undefined;
  status: string;
};

export const Calendars: React.FC<FormStep1Type> = memo(({
  control,
  registrationData,
  branchId,
  isSelectedCalendarType,
  setSelectedDesiredDate,
  setDesiredDateCallback,
  prevWeek,
  nextWeek,
  registration,
  setSelectedDesiredDateCallback,
  generalDate,
  setGeneralDate,
  valueTime,
  setTimeDefaultValueCallback,
  selectedTimeDesired,
  setChoicesTime,
  setSelectedTime,
  setSelectedDate,
  setFreeDateCallback,
  isCollapsedCalendar,
  setIsDesiredDate,
  setValueCallback,
  setSelectedAvailableDate,
  oChangeStatusCalendar,
  onChangeCalendarType,
  setClassNameBtn,
  setDisabledBtn,
  status,
}) => {
  return (
    <>
      {registrationData.selectedDoctor?.id ? (
        <div className="calendar">
          {isSelectedCalendarType && registrationData.branchInfo.desired_date ? (
            <CalendarDesiredDate
              selectedDate={registrationData.selectedDate}
              setSelectedDate={setSelectedDesiredDate}
              setDesiredDate={setDesiredDateCallback}
              onPrevWeek={() => {
                prevWeek(branchId, registration.previous_week, registrationData.selectedReason);
              }}
              onNextWeek={() => {
                nextWeek(branchId, registration.next_week, registrationData.selectedReason);
              }}
              control={control}
              name={'time'}
              setSelectedDesiredDateCallback={setSelectedDesiredDateCallback}
              valueDateTime={generalDate}
              setValueDateTime={setGeneralDate}
              valueTime={valueTime}
              setTimeDefaultValue={setTimeDefaultValueCallback}
              timeDefaultValue={selectedTimeDesired}
              setChoicesTimeCallback={setChoicesTime}
              choicesTime={registrationData.choicesTime}
              setSelectedTime={setSelectedTime}
            />
          ) : registrationData.branchInfo.free_date ? (
            <CalendarDesiredDate
              setSelectedDate={setSelectedDate}
              setDesiredDate={setFreeDateCallback}
              onPrevWeek={() => {
                prevWeek(branchId, registration.previous_week, registrationData.selectedReason);
              }}
              onNextWeek={() => {
                nextWeek(branchId, registration.next_week, registrationData.selectedReason);
              }}
              control={control}
              name={'time'}
              selectedDate={registrationData.selectedDate}
              valueDateTime={generalDate}
              setValueDateTime={setGeneralDate}
              valueTime={valueTime}
              setTimeDefaultValue={setTimeDefaultValueCallback}
              timeDefaultValue={selectedTimeDesired}
              setChoicesTimeCallback={setChoicesTime}
              choicesTime={registrationData.choicesTime}
              setSelectedTime={setSelectedTime}
            />
          ) : (
            <CalendarAvailableDate
              isCollapsedCalendar={isCollapsedCalendar}
              onPrevWeek={() => {
                prevWeek(branchId, registration.previous_week, registrationData.selectedReason);
              }}
              onNextWeek={() => {
                nextWeek(branchId, registration.next_week, registrationData.selectedReason);
              }}
              registration={registration}
              setDesiredDate={() => {
                setIsDesiredDate(false);
              }}
              valueTime={valueTime}
              setValueTime={setValueCallback}
              setValueDateTime={setGeneralDate}
              setChosenDate={setSelectedDate}
              previousWeek={registration.previous_week}
              nextWeek={registration.next_week}
              setSelectedAvailableDate={setSelectedAvailableDate}
              selectedAvailableDate={registrationData.selectedDate}
              status={status}
            />
          )}

          <div className="links-block">
            {isSelectedCalendarType
              ? !registrationData.branchInfo.free_date && (
                  <span className="btn-link-disabled">
                    {isCollapsedCalendar ? 'weitere Zeiten' : 'weniger Zeit'}
                  </span>
                )
              : !registrationData.branchInfo.free_date && (
                  <span className="btn-link" onClick={oChangeStatusCalendar}>
                    {isCollapsedCalendar ? 'weitere Zeiten' : 'weniger Zeit'}
                  </span>
                )}

            {registrationData.branchInfo.desired_date && !registrationData.branchInfo.free_date && (
              <span className="btn-link" onClick={onChangeCalendarType}>
                {isSelectedCalendarType ? 'Termindatum auswählen' : 'Wunschtermin vorschlagen'}
              </span>
            )}
          </div>

          <button className={setClassNameBtn()} disabled={setDisabledBtn()}>
            Jetzt vereinbaren
          </button>
        </div>
      ) : (
        <div className="calendar-null">
          <div className="calendar-null__info">
            <img
              alt="calendar is empty"
              className="calendar-null__info-img"
              src={`${baseURL_WIDGET}static/media/time-registration.45764dbf2f4b75250836506132fae18d.svg`}
            />
            <span className="calendar-null__info-text">
              Bitte wählen Sie zuerst den Grund für Ihren Besuch aus{' '}
            </span>
          </div>
        </div>
      )}
    </>
  );
});
