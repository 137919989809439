import { registrationReducer } from './registration-reducer';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { fieldsReducer } from './fields-reducer';
import { appReducer } from './app-reducer';

const rootReducer = combineReducers({
  app: appReducer,
  fields: fieldsReducer,
  registration: registrationReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export type AppRootStateType = ReturnType<typeof rootReducer>;

// @ts-ignore
window.store = store;