import { Dispatch } from 'redux';
import { fieldsAPI, RegistrationListFieldsType } from '../api/fields-api';
import { handleServerAppError, handleServerNetworkError } from '../utils/error-utils';

const SET_LIST_FIELDS_REGISTRATION = 'employeesReducer/SET_LIST_FIELDS_REGISTRATION';

const InitialModelField = {
  help_text: '',
  hidden: false,
  initial_value: null,
  input_type: '',
  label: '',
  required: false,
  type: '',
  widget_type: '',
};

const InitialModelFieldWithChoices = {
  choices: {},
  help_text: '',
  hidden: false,
  initial_value: null,
  input_type: '',
  label: '',
  required: false,
  type: '',
  widget_type: '',
};

const initialState = {
  registrationFields: {
    date_birth: InitialModelField,
    email: InitialModelField,
    first_name: InitialModelField,
    gender: InitialModelFieldWithChoices,
    insurance_type: InitialModelFieldWithChoices,
    last_name: InitialModelField,
    message: InitialModelField,
    phone: InitialModelField,
    reason: InitialModelFieldWithChoices,
    start: InitialModelField,
    desired_date: InitialModelField,
    doctor: InitialModelFieldWithChoices,
  } as RegistrationListFieldsType,
};

type InitialStateType = {
  registrationFields: RegistrationListFieldsType;
};

export const fieldsReducer = (
  state: InitialStateType = initialState,
  action: ActionsType,
): InitialStateType => {
  switch (action.type) {
    case SET_LIST_FIELDS_REGISTRATION: {
      return { ...state, registrationFields: action.fields };
    }

    default:
      return state;
  }
};

// actions

export const setListFieldsRegistrationAC = (fields: RegistrationListFieldsType) =>
  ({ type: SET_LIST_FIELDS_REGISTRATION, fields } as const);

// thunk

export const fetchRegistrationListFieldsTC =
  (branchId: number | null) => async (dispatch: Dispatch<any>) => {
    try {
      let res = await fieldsAPI.getListFieldRegistration(branchId);
      dispatch(setListFieldsRegistrationAC(res.data));
    } catch (error: any) {
      if (error.message === 'Network Error') {
        handleServerNetworkError(error, dispatch);
      } else {
        handleServerAppError(error, dispatch);
      }
    }
  };

//types
type ActionsType = ReturnType<typeof setListFieldsRegistrationAC>;
