import React, { FC } from 'react';

type ErrorMessagePropsType = {
  status: string;
  errorState: string;
};

export const ErrorMessage: FC<ErrorMessagePropsType> = ({ status, errorState }) => {
  return (
    <>
      {status === 'failed' && errorState && typeof errorState === 'object' ? (
        <p className="general-error">{errorState && Object.values(errorState)[0]}</p>
      ) : status === 'failed' && errorState && typeof errorState !== 'object' ? (
        <p className="general-error">{errorState}</p>
      ) : (
        <p className="general-error"></p>
      )}
    </>
  );
};
