import Radio from '../../../../components/Widgets/Radio';
import '../Step1.scss';
import React, { ChangeEvent, FC } from 'react';
import { InitialStateType } from '../../../../redux/registration-reducer';
import { ErrorMessage } from '../../../../components/ErrorMessage/ErrorMessage';
import { useSelector } from 'react-redux';
import {
  getDoctorsInfoSelector,
  getErrorStateSelector,
  getStatusSelector,
} from '../../../../redux/selectors';
import { RegistrationListFieldsType } from '../../../../api/fields-api';
import { DoctorType } from '../../../../api/registration-api';
import RadioWithPhoto from '../../../../components/Widgets/RadioWithPhoto';
import { baseURL_API, baseURL_WIDGET } from '../../../../utils/BaseURL';

type ChoicePropsType = {
  valueOptionInsurance: { value: string; label: string }[];
  register: any;
  registrationData: InitialStateType;
  setInsuranceType: (event: ChangeEvent<HTMLInputElement>) => void;
  valueOptionReasons: { value: string; label: string }[];
  valueOptionDoctor: { value: string; label: string }[];
  getDate: (doctorId: number) => void;
  getDoctorInfo: (branchId: number, reasonId: number) => void;
  branchId: number;
  setReason: (event: ChangeEvent<HTMLInputElement>) => void;
  fields: RegistrationListFieldsType;
  setSelectedDoctor: (doctor: DoctorType | null) => void;
  reset: any;
};

export const VisitOptionsForm: FC<ChoicePropsType> = ({
  valueOptionInsurance,
  register,
  registrationData,
  setInsuranceType,
  valueOptionReasons,
  getDate,
  getDoctorInfo,
  branchId,
  setReason,
  fields,
  setSelectedDoctor,
}) => {
  const errorState = useSelector(getErrorStateSelector);
  const status = useSelector(getStatusSelector);
  const doctorInfo = useSelector(getDoctorsInfoSelector);

  const doctorPhotos = (doctorId: number): string => {
    const photoDoctorUrl = doctorInfo.find((doc) => doc.id === doctorId)?.photo;

    return photoDoctorUrl
      ? `${baseURL_API}${photoDoctorUrl}`
      : `${baseURL_WIDGET}static/media/doctor.b6f41246e0278d64f1f48640d71f6144.svg`;
  };

  const doctorsReason =
    doctorInfo &&
    doctorInfo.map((doc) => ({ ...doc, fullName: `${doc.first_name} ${doc.last_name}` }));

  return (
    <div className="choice">
      <div className="choice__incurance">
        <h2 className="choice__incurance-name">{fields.insurance_type.label}</h2>
        {valueOptionInsurance &&
          valueOptionInsurance.map((insurance) => (
            <div className="radio-elem" key={insurance.value}>
              <Radio
                key={insurance.value}
                name="insurance_type"
                register={register}
                value={insurance.value}
                defaultChecked={registrationData.selectedInsuranceType === insurance.value}
                onChange={setInsuranceType}
                id={`${insurance.value}`}
                label={insurance.label}
              />
            </div>
          ))}
      </div>

      <div className="choice__reason">
        <h2 className="choice__reason-name">{fields.reason.label}</h2>
        <div className="checkbox-reason">
          {valueOptionReasons &&
            valueOptionReasons.map((reason) => (
              <div className="radio-elem" key={reason.value}>
                <Radio
                  key={reason.value}
                  name="reason"
                  register={register}
                  value={reason.value}
                  defaultChecked={registrationData.selectedReason === reason.value}
                  onClick={() => {
                    setSelectedDoctor(null);
                    getDoctorInfo(branchId, Number(reason.value));
                  }}
                  onChange={(e) => {
                    setReason(e);
                  }}
                  id={`${reason.value}`}
                  label={reason.label}
                />
              </div>
            ))}
        </div>
      </div>

      {doctorsReason.length >= 1 && (
        <div className="choice__doctor">
          <h2 className="choice__doctor-name">{fields.doctor.label}</h2>

          {doctorsReason &&
            doctorsReason.map((doctor) => (
              <div className="radio-elem" key={doctor.id}>
                <RadioWithPhoto
                  key={doctor.id}
                  name="doctor"
                  photo={doctorPhotos(doctor.id)}
                  register={register}
                  value={doctor.id}
                  defaultChecked={
                    registrationData.selectedDoctor?.id ===
                    doctor.id
                  }
                  onClick={() => {
                    getDate(doctor.id);
                    setSelectedDoctor(doctor);
                  }}
                  id={`${doctor.id}`}
                  label={doctor.fullName}
                />
              </div>
            ))}
        </div>
      )}

      <ErrorMessage status={status} errorState={errorState} />
    </div>
  );
};