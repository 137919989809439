import './CheckboxInput.scss';
import React, { ChangeEvent, DetailedHTMLProps, FC, InputHTMLAttributes, useState } from 'react';
import { Path } from 'react-hook-form';
import { FormDataModalType } from './Radio';

type DefaultInputPropsType = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type CheckboxInputType = DefaultInputPropsType & {
  onChangeChecked?: (checked: boolean) => void;
  spanClassName?: string;
  register?: any;
  id: string;
  label?: any;
  state?: string;
  name?: Path<FormDataModalType> | string;
  input_type?: string;
  disabled?: boolean;
  help_text?: string;
  color?: any;
};

const CheckboxInput: FC<CheckboxInputType> = ({
  state,
  onChange,
  onChangeChecked,
  register,
  id,
  help_text,
  color,
  label,
  disabled,
  name,
  defaultChecked,

  ...restProps
}) => {
  const onChangeCallback = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange && onChange(e);

    onChangeChecked && onChangeChecked(e.currentTarget.checked);
  };
  const customCheckboxClass = `
        checkbox checkbox_${state}
    `;
  const [check, setCheck] = useState(true);
  const changeColor = (): void => {
    if (check) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  };

  return (
    <div className={customCheckboxClass}>
      <input
        state={state}
        {...(register && { ...register(name) })}
        type="checkbox"
        onChange={onChangeCallback}
        name={name}
        help_text={help_text}
        color={color}
        id={`id_${id}`}
        className="checkbox__input"
        defaultChecked={defaultChecked}
        disabled={disabled}
        label={label}
        {...restProps}
        onClick={changeColor}
      />
      <label className="checkbox__label" htmlFor={`id_${id}`}>
        <span className={check ? 'checkbox__text1' : 'checkbox__text'}>
          <div className="d">{label}</div>
        </span>
      </label>
    </div>
  );
};

export default CheckboxInput;
