import React, { useState } from 'react';
import { ReactSelect } from '../../../../components/Widgets/Select/ReactSelect';
import Inputs from '../../../../components/Widgets/Inputs';
import Textarea from '../../../../components/Widgets/Textarea';
import CheckboxInput from '../../../../components/Widgets/CheckboxInput';
import { ErrorMessage } from '../../../../components/ErrorMessage/ErrorMessage';
import '../Step2.scss';
import { ValidationErrorType } from '../../../../utils/useErrorHandler';
import { RegistrationListFieldsType } from '../../../../api/fields-api';
import {
  InitialStateType,
  setSelectedBirthDateAC,
  setSelectedCommentAC,
  setSelectedEmailAC,
  setSelectedFirstNameAC,
  setSelectedGenderAC,
  setSelectedLastNameAC,
  setSelectedPhoneAC,
} from '../../../../redux/registration-reducer';
import {
  defaultChoicesSelect,
  newOption,
} from '../../../../components/Widgets/Select/ReactSelectStyles';
import { useDispatch } from 'react-redux';
import { ReactDatePicker } from '../../../../components/Widgets/ReactDatePicker';

type FormStep2PropsType = {
  handleSubmit: any;
  onSubmit: any;
  genderErrorHandler: ValidationErrorType;
  birthDateErrorHandler: ValidationErrorType;
  firstNameErrorHandler: ValidationErrorType;
  lastNameErrorHandler: ValidationErrorType;
  emailNameErrorHandler: ValidationErrorType;
  phoneNameErrorHandler: ValidationErrorType;
  fields: RegistrationListFieldsType;
  defaultValueGender: { value: any; label: any };
  registrationData: InitialStateType;
  valueOptionGender: { value: string; label: string }[];
  labelOptionGender: any;
  control: any;
  choicesGender: [string, unknown][];
  register: any;
  reset: () => void;
  dateDefaultValue: any;
  changeChecked: () => void;
  checked: boolean;
  errorState: string;
  status: string;
};

export const FormStep2: React.FC<FormStep2PropsType> = ({
  handleSubmit,
  onSubmit,
  genderErrorHandler,
  fields,
  defaultValueGender,
  registrationData,
  valueOptionGender,
  labelOptionGender,
  choicesGender,
  birthDateErrorHandler,
  firstNameErrorHandler,
  lastNameErrorHandler,
  emailNameErrorHandler,
  phoneNameErrorHandler,
  dateDefaultValue,
  changeChecked,
  checked,
  status,
  control,
  errorState,
  register,
  reset,
}) => {
  const dispatch = useDispatch();

  const [selectedDate] = useState<Date>(new Date());

  const setSelectedHandlerHandler = (gender: { value: string; label: string }): void => {
    dispatch(setSelectedGenderAC(gender));
  };

  const setSelectedBirthDateHandler = (birth_date: string): void => {
    dispatch(setSelectedBirthDateAC(birth_date));
  };

  const setSelectedFirstNameHandler = (first_name: string): void => {
    dispatch(setSelectedFirstNameAC(first_name));
  };

  const setSelectedLastNameHandler = (last_name: string): void => {
    dispatch(setSelectedLastNameAC(last_name));
  };

  const setSelectedEmailHandler = (email: string): void => {
    dispatch(setSelectedEmailAC(email));
  };

  const setSelectedCommentHandler = (comment: string): void => {
    dispatch(setSelectedCommentAC(comment));
  };

  const setSelectedPhoneHandler = (phone: string): void => {
    dispatch(setSelectedPhoneAC(phone));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="forms">
      <label className="forms__info">Persönliche Daten</label>
      <div className="forms__personal">
        <div className="s">
          <ReactSelect
            name="gender"
            error={genderErrorHandler.error}
            help_text={genderErrorHandler.error && genderErrorHandler.errorMessageCurrentField[1]}
            label={fields.gender.label}
            placeholder={fields.gender.label}
            onClickError={genderErrorHandler.onFieldClick}
            onChangeInputObject={setSelectedHandlerHandler}
            defaultValue={
              defaultValueGender && defaultValueGender.value !== ''
                ? defaultValueGender && defaultValueGender.value
                : registrationData && defaultChoicesSelect(valueOptionGender, labelOptionGender)[0]
                ? registrationData && defaultChoicesSelect(valueOptionGender, labelOptionGender)[0]
                : null
            }
            control={control}
            isMulti={false}
            options={
              choicesGender &&
              Object.entries(fields.gender.choices).map((b: any) => newOption(b[0], b[1]))
            }
          />
        </div>
        {/*<Inputs*/}
        {/*  error={birthDateErrorHandler.error}*/}
        {/*  help_text={*/}
        {/*    birthDateErrorHandler.error && birthDateErrorHandler.errorMessageCurrentField[1]*/}
        {/*  }*/}
        {/*  onClick={birthDateErrorHandler.onFieldClick}*/}
        {/*  state={'date'}*/}
        {/*  register={register}*/}
        {/*  input_type={'date'}*/}
        {/*  name={'date_birth'}*/}
        {/*  label={fields && fields.date_birth.label}*/}
        {/*  onChangeText={setSelectedBirthDateHandler}*/}
        {/*  defaultValue={*/}
        {/*    registrationData.selectedBirthDate*/}
        {/*      ? registrationData.selectedBirthDate*/}
        {/*      : dateDefaultValue*/}
        {/*  }*/}
        {/*  resetForm={reset}*/}
        {/*  {...reset}*/}
        {/*/>*/}

        <ReactDatePicker
          error={birthDateErrorHandler.error}
          help_text={
            birthDateErrorHandler.error && birthDateErrorHandler.errorMessageCurrentField[1]
          }
          onClick={birthDateErrorHandler.onFieldClick}
          register={register}
          name={'date_birth'}
          label={fields && fields.date_birth.label}
          onChangeText={setSelectedBirthDateHandler}
          defaultValue={
            registrationData.selectedBirthDate
              ? registrationData.selectedBirthDate
              : dateDefaultValue
          }
          selectedDate={selectedDate}
          control={control}
        />

        <Inputs
          error={firstNameErrorHandler.error}
          help_text={
            firstNameErrorHandler.error && firstNameErrorHandler.errorMessageCurrentField[1]
          }
          onClick={firstNameErrorHandler.onFieldClick}
          state={'active'}
          register={register}
          input_type={fields && fields.first_name.input_type}
          name={'first_name'}
          label={fields && fields.first_name.label}
          onChangeText={setSelectedFirstNameHandler}
          defaultValue={registrationData.selectedFirstName && registrationData.selectedFirstName}
          resetForm={reset}
          {...reset}
        />
        <Inputs
          error={lastNameErrorHandler.error}
          onClick={lastNameErrorHandler.onFieldClick}
          help_text={lastNameErrorHandler.error && lastNameErrorHandler.errorMessageCurrentField[1]}
          state={'active'}
          register={register}
          input_type={fields && fields.last_name.input_type}
          onChangeText={setSelectedLastNameHandler}
          defaultValue={registrationData.selectedLastName && registrationData.selectedLastName}
          name={'last_name'}
          label={fields && fields.last_name.label}
          resetForm={reset}
          {...reset}
        />
      </div>
      <div className="forms__contacts">
        <div className="number">
          <label className="forms__number">Kontakt</label>
          <Inputs
            error={emailNameErrorHandler.error}
            onClick={emailNameErrorHandler.onFieldClick}
            help_text={
              emailNameErrorHandler.error && emailNameErrorHandler.errorMessageCurrentField[1]
            }
            state={'active'}
            register={register}
            name={'email'}
            type={fields.email.input_type}
            onChangeText={setSelectedEmailHandler}
            defaultValue={registrationData.selectedEmail && registrationData.selectedEmail}
            label={fields && fields.email.label}
            resetForm={reset}
            {...reset}
          />
          <Inputs
            error={phoneNameErrorHandler.error}
            onClick={phoneNameErrorHandler.onFieldClick}
            help_text={
              phoneNameErrorHandler.error && phoneNameErrorHandler.errorMessageCurrentField[1]
            }
            state={'active'}
            register={register}
            name={'phone'}
            label={fields && fields.phone.label}
            onChangeText={setSelectedPhoneHandler}
            defaultValue={
              registrationData.selectedPhone
                ? registrationData.selectedPhone
                : fields.phone.initial_value
            }
            resetForm={reset}
            {...reset}
          />
        </div>
        <div className="comment">
          <label className="forms__comment">Kommentare (optional)</label>
          <Textarea
            help_text={fields.message.help_text}
            state={'active'}
            resize="none"
            register={register}
            name={'message'}
            label={fields && fields.message.label}
            onChangeText={setSelectedCommentHandler}
            defaultValue={
              registrationData.selectedComment
                ? registrationData.selectedComment
                : fields.message.initial_value
            }
            resetForm={reset}
            {...reset}
          />
        </div>
      </div>
      <div className="agreement">
        <span className="privacy-policy">
          <span className="check">
            <CheckboxInput
              defaultChecked={registrationData.isPrivacyPolicy}
              onChangeChecked={changeChecked}
              id={`${'2'}`}
              register={register}
              name="is_weekend"
            />
          </span>
          {`Ich bin einverstanden, dass meine hier abgegebenen Daten zum Zweck der
                            Terminbuchung von der terminow.de verarbeitet werden und verschlüsselt
                            an den behandelnden Arzt übermittelt werden. Ich bin ebenfalls damit
                            einverstanden, dass mir terminow.de im Auftrag von dem behandelnden
                            Arzt Terminbestätigungen und Erinnerungen per E-Mail und SMS schickt
                            und meine E-Mail-Adresse und Handynummer an den behandelnden Arzt
                            übermittelt, damit dieser mich zukünftig im Rahmen der Behandlung
                            auf diesen Wegen kontaktieren kann. Diese Einwilligung ist jederzeit
                            für die Zukunft widerrufbar. Durch den Widerruf der Einwilligung wird
                            die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
                            erfolgten Verarbeitung nicht berührt. Weitere Informationen finden Sie in der `}
          <a className="link-privacy-policy" href="https://www.terminow.de/privacy-policy/">
            Datenschutzerklärung
          </a>
        </span>
      </div>
      <div className="btn">
        <button
          disabled={!checked}
          className={
            !checked
              ? 'button button_size-middle  button_color-disabled'
              : 'button button_size-middle  button_color-black'
          }
        >
          Termin buchen
        </button>
      </div>

      <ErrorMessage status={status} errorState={errorState} />
    </form>
  );
};
