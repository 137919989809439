const SET_STATUS = 'appReducer/SET-STATUS';
const SET_ERROR = 'appReducer/SET_ERROR';

const initialState: InitialStateType = {
  status: 'idle',
  error: '',
};

export const appReducer = (
  state: InitialStateType = initialState,
  action: ActionsType,
): InitialStateType => {
  switch (action.type) {
    case SET_STATUS:
      return { ...state, status: action.status };

    case SET_ERROR:
      return { ...state, error: action.error };

    default:
      return { ...state };
  }
};

//action

export const setAppErrorAC = (error: string) => ({ type: SET_ERROR, error } as const);

export const setAppStatusAC = (status: RequestStatusType) =>
  ({ type: SET_STATUS, status } as const);

//types
export type RequestStatusType = 'idle' | 'loading' | 'succeeded' | 'failed';

export type InitialStateType = {
  status: RequestStatusType;
  error: string;
};

export type ActionsType = ReturnType<typeof setAppStatusAC> | ReturnType<typeof setAppErrorAC>;
