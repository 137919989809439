import './Textarea.scss';

import { ChangeEvent, DetailedHTMLProps, FC, TextareaHTMLAttributes, useState } from 'react';
import { Path } from 'react-hook-form';
import { FormDataType } from './URLInput';

type DefaultTextareaPropsType = DetailedHTMLProps<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

type TextareaType = DefaultTextareaPropsType & {
  state?: string;
  input_name?: string;
  error?: string | null;
  help_text?: string | null;
  label?: string;
  img?: any;
  name?: Path<FormDataType> | string;
  onEnter?: () => void;
  resetForm?: () => void;
  onChangeText?: (value: string) => void;
  register?: any;
  input_type?: string;
  defaultValue?: any;
  height?: string;
  width?: string;
  resize?: string;
};

const Textarea: FC<TextareaType> = ({
  onChange,
  onChangeText,
  error,
  name,
  state,
  img,
  defaultValue,
  /*input_name,*/ help_text,
  label,
  register,
  ...restProps
}) => {
  const [focused, setFocus] = useState(false);
  const [value, setValue] = useState('');

  const onFocusHandler = (): void => {
    setFocus(true);
  };

  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    onChange && onChange(e);
    onChangeText && onChangeText(e.currentTarget.value);
    setValue(e.target.value);
  };

  const customTextareaClass = ` 
    area area_${state} 
    area_${img} 
   
    ${error ? 'area_error ' : ''}
    ${focused ? 'area_focus' : 'area_unfocus'} 
    ${value.length ? 'area_focus' : 'area_unfocus'}
    ${defaultValue ? 'area_focus' : 'area_unfocus'}`;

  return (
    <div className={customTextareaClass}>
      {register ? (
        <textarea
          className="area__text"
          defaultValue={defaultValue}
          {...register(name, {
            onBlur: () => {
              if (value !== '') {
                setFocus(false);
              } else {
                setFocus(false);
              }
            },
            onChange: onChangeHandler,
          })}
          id={`id_${name}`}
          onFocus={onFocusHandler}
          onChange={onChangeHandler}
          disabled={state === 'disabled'}
          {...restProps}
        />
      ) : (
        <textarea
          className="area__text"
          defaultValue={defaultValue}
          id={`id_${name}`}
          onFocus={onFocusHandler}
          onChange={onChangeHandler}
          disabled={state === 'disabled'}
        />
      )}

      <label className="area__label" htmlFor={`id_${name}`}>
        {label}
      </label>
      <span className="area__help-text">{help_text}</span>
    </div>
  );
};

export default Textarea;
