/* eslint-disable */
export const loadGlobalBranchId = () => {
  try {
    const branchIdJSON = localStorage.getItem('branchId');
    if (branchIdJSON === null) {
      return undefined;
    }
    return JSON.parse(branchIdJSON);
  } catch (err) {
    return undefined;
  }
};