import React, { FC, useEffect, useState } from 'react';
import './Calendar.scss';
import Calendar, { Detail } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';
import { baseURL_WIDGET } from '../../utils/BaseURL';

type Calendar = {
  setDate?: (date: Date) => void;
  onPrevClick?: () => void;
  onNextClick?: () => void;
  branchId?: number;
  chosenWeekInCalendar?: (branchId: number, week: string) => void;
  onDayClick?: () => void;
  onDayClickCustom?: (date: string) => void;
  disabled?: boolean;
  disabledCallback?: (activeStartDate: Date, date: Date, view: Detail) => boolean;
  valueDateTime?: Date; //
  isDayWorked?: (value: Date) => void;
  setValueDateTime?: (value: Date) => void;
  selectedDate?: string;
  isCalendar: boolean;
};

export const CalendarGeneral: FC<Calendar> = ({
  setDate,
  onPrevClick,
  onNextClick,
  branchId,
  chosenWeekInCalendar,
  onDayClick,
  disabled,
  disabledCallback,
  onDayClickCustom,
  valueDateTime,
  isDayWorked,
  setValueDateTime,
  selectedDate,
  isCalendar,
}) => {
  const [startDate, setStartDate] = useState(new Date());

  const valueFromAvailable = valueDateTime && new Date(valueDateTime);
  const valueSelectedDate = selectedDate && new Date(selectedDate);
  const isWeekEnd = valueDateTime && isDayWorked && isDayWorked(valueDateTime);

  useEffect(() => {
    if (valueDateTime && !selectedDate) {
      setDate && setDate(valueDateTime);
    }
    if (selectedDate) {
      setDate && setDate(new Date(selectedDate));
    }
  }, [selectedDate]);

  useEffect(() => {
    if (isCalendar && !selectedDate) {
      const currentDate = new Date();
      setDate && setDate(currentDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    setDate && setDate(startDate);
  }, []);

  const onDateClick = (date: Date): void => {
    setValueDateTime && setValueDateTime(date);
    setStartDate(date);

    setDate && setDate(date);

    if (chosenWeekInCalendar && branchId) {
      chosenWeekInCalendar(branchId, dayjs(date).format('DD-MM-YYYY'));
      onDayClick && onDayClick();
    }
    onDayClickCustom && onDayClickCustom(dayjs(date).format('YYYY-MM-DD'));
  };

  const valueCurrentDate =
    valueFromAvailable && !isWeekEnd
      ? valueFromAvailable
      : valueSelectedDate
      ? valueSelectedDate
      : startDate;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Calendar
        onChange={onDateClick}
        onActiveStartDateChange={({ action }) => {
          if (action === 'prev') {
            onPrevClick && onPrevClick();
          }
          if (action === 'next') {
            onNextClick && onNextClick();
          }
        }}
        tileDisabled={
          disabled && disabledCallback
            ? ({ activeStartDate, date, view }) => disabledCallback(activeStartDate, date, view)
            : () => false
        }
        // value={(valueFromAvailable && !isWeekEnd) ? valueFromAvailable : startDate}
        value={valueCurrentDate}
        nextLabel={
          <img
            src={`${baseURL_WIDGET}static/media/next.389c39b66c64765fac5b0fbe4adc7514.svg`}
            alt="next"
          />
        }
        prevLabel={
          <img
            src={`${baseURL_WIDGET}static/media/prev.fa37c639134763de6ddd8f11d443d08a.svg`}
            alt="previous"
          />
        }
        next2Label={null}
        prev2Label={null}
        // showWeekNumbers={true}
        // view={"month"}
        locale={'en'}
      />
    </div>
  );
};