import './Radio.scss';
import { ChangeEvent, DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import { Path } from 'react-hook-form';

export type FormDataModalType = {
  day_of_week: string;
  start_time_work: string;
  end_time_work: string;
  start_lunch_break: string | null;
  end_lunch_break: string | null;
  is_weekend: false;
  lunch_break: false;
};

type DefaultInputPropsType = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type RadioType = DefaultInputPropsType & {
  state?: string;
  name?: Path<FormDataModalType> | string;
  register?: any;
  spanClassName?: string;
  value?: any;
  checked?: boolean;
  input_type?: string;
  disabled?: boolean;
  label?: any;
  onChangeChecked?: (checked: boolean) => void;
};

const Radio: FC<RadioType> = ({
  state,
  onChange,
  onChangeChecked,
  register,
  id,
  value,
  label,
  disabled,
  name,
  defaultChecked,
  ...restProps
}) => {
  const onChangeCallback = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange && onChange(e);

    onChangeChecked && onChangeChecked(e.currentTarget.checked);
  };
  const customCheckboxClass = `
        radioTerminow radioTerminow_${state}
    `;
  return (
    <div className={customCheckboxClass}>
      <input
        state={state}
        className="radioTerminow__input"
        type="radio"
        name={name}
        {...(register && { ...register(name) })}
        id={`id_${id}`}
        value={value}
        disabled={disabled}
        onChange={onChangeCallback}
        defaultChecked={defaultChecked}
        label={label}
        {...restProps}
      />
      <label className="radioTerminow__label" htmlFor={`id_${id}`}>
        <div className="radioTerminow__d">{label}</div>
      </label>
    </div>
  );
};

export default Radio;
