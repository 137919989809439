import React, { FC } from 'react';
import { DataOfClientsType, TimeSlotsType } from '../../../../../api/registration-api';
import { baseURL_WIDGET } from '../../../../../utils/BaseURL';
import Preloader from '../../../../../components/Preloader/Preloader';

type CalendarWithTimeType = {
  isCollapsedCalendar: boolean;
  onPrevWeek: () => void;
  onNextWeek: () => void;
  registration: DataOfClientsType;
  valueTime: string;
  setValueTime: (value: string, start: string) => void;
  setValueDateTime: (value: Date) => void;
  setDesiredDate: () => void;
  setChosenDate?: (value: string) => void;
  previousWeek: number;
  nextWeek: number;
  setSelectedAvailableDate: (value: string) => void;
  selectedAvailableDate?: string;
  status: string;
};

export const CalendarAvailableDate: FC<CalendarWithTimeType> = ({
  isCollapsedCalendar,
  onPrevWeek,
  onNextWeek,
  registration,
  valueTime,
  setValueTime,
  setDesiredDate,
  setChosenDate,
  setValueDateTime,
  previousWeek,
  nextWeek,
  setSelectedAvailableDate,
  selectedAvailableDate,
  status,
}) => {
  const onPrevClick = (): void => {
    if (previousWeek) {
      onPrevWeek && onPrevWeek();
    }
  };

  const onNextClick = (): void => {
    if (nextWeek) {
      onNextWeek && onNextWeek();
    }
  };

  const onDayClick = (date: string, time_slots: Array<TimeSlotsType>): void => {
    if (time_slots.length) {
      setSelectedAvailableDate(date);
    }
  };

  const onTimeClick = (full_date: string, start: string): void => {
    setValueTime(full_date, start);
    setDesiredDate();
    setChosenDate && setChosenDate('');
    setValueDateTime(new Date(full_date));
  };

  return (
    <>
      <div
        className="calendar__info"
        style={
          !isCollapsedCalendar ? { overflow: 'visible' } : { overflow: 'hidden', height: '360px' }
        }
      >
        <div className="calendar__info-control-block">
          <div className={previousWeek ? 'prev' : 'prev-null'} onClick={onPrevClick}>
            <img
              src={`${baseURL_WIDGET}static/media/prev.fa37c639134763de6ddd8f11d443d08a.svg`}
              alt="previous"
            />
          </div>

          <span className="week">{registration && registration.first_last_date_week}</span>

          <div className={nextWeek ? 'next' : 'next-null'} onClick={onNextClick}>
            <img
              src={`${baseURL_WIDGET}static/media/next.389c39b66c64765fac5b0fbe4adc7514.svg`}
              alt="next"
            />
          </div>
        </div>

        <hr className="border-up" />

        {status === 'loading' ? (
          <Preloader />
        ) : (
          <div className="column-calendar">
            {registration.dates_slots &&
              registration.dates_slots.map((date) => (
                <ul key={date.date} className="list-time">
                  <ul
                    className="time-item"
                    onClick={() => {
                      onDayClick(date.date, date.time_slots);
                    }}
                  >
                    <span className="title-date">{date.day_name}</span>
                    <li>{date.date}</li>
                    {date.time_slots &&
                      date.time_slots.map((time) => (
                        <li
                          className={`${
                            valueTime === time.full_date || time.full_date === selectedAvailableDate
                              ? 'title-time-chosen'
                              : 'title-time'
                          }`}
                          key={time.start}
                          onClick={() => {
                            onTimeClick(time.full_date, time.start);
                          }}
                        >
                          {time.start}
                        </li>
                      ))}
                  </ul>
                </ul>
              ))}
          </div>
        )}
      </div>
    </>
  );
};