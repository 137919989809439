import { SubmitHandler, useForm } from 'react-hook-form';
import './Step1.scss';
import React, { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRegistrationListFieldsTC } from '../../../redux/fields-reducer';
import {
  getBranchInfoTC,
  getDateRegistrationFirstRenderTC,
  getDateRegistrationTC,
  getDoctorInfoTC,
  setChoicesTimeAC,
  setDataStep1,
  setDesiredDateAC,
  setSelectedAvailableDateAC,
  setSelectedDesiredDateAC,
  setSelectedDesiredTimeAC,
  setSelectedDoctorAC,
  setSelectedInsuranceTypeAC,
  setSelectedReasonTypeAC,
  setSelectedTimeDesiredDateAC,
} from '../../../redux/registration-reducer';
import { newOption } from '../../../components/Widgets/Select/ReactSelectStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getFieldsSelector,
  getRegistrationDataSelector,
  getRegistrationSelector,
  getSelectedDoctorSelector,
  getSelectedTimeDesiredSelector,
  getStatusSelector,
} from '../../../redux/selectors';
import { setStateSelectedFields } from '../../../utils/setStateSelectedFields';
import { DoctorType } from '../../../api/registration-api';
import dayjs from 'dayjs';
import { Calendars } from './Calendars/Calendars';
import { VisitOptionsForm } from './VisitOptionsForm/VisitOptionsForm';
import { loadGlobalBranchId } from '../../../utils/localeStorage';

export type FormDataModalType = {
  start: string;
  insurance_type: string;
  reason: any;
  time: { value: string; label: string };
  desired_date: boolean;
  doctor: number;
};

type Step1Type = {
  setCurrentPage: (value: string) => void;
  step2Hash: string;
  step2: string;
};

export const Step1: React.FC<Step1Type> = memo(({ setCurrentPage, step2Hash, step2 }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const registration = useSelector(getRegistrationSelector);
  const registrationData = useSelector(getRegistrationDataSelector);
  const fields = useSelector(getFieldsSelector);
  const selectedTimeDesired = useSelector(getSelectedTimeDesiredSelector);
  const selectedDoctor = useSelector(getSelectedDoctorSelector);
  const status = useSelector(getStatusSelector);

  const [valueTime, setValueTime] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<string>('');

  const [generalDate, setGeneralDate] = useState<Date>();
  const [selectedDesiredTime, setSelectedDesiredTime] = useState<any>();

  const [isDesiredDate, setIsDesiredDate] = useState<boolean>(false);
  const [isSelectedDesiredTime, setIsSelectedDesiredTime] = useState<boolean>(false);
  const [isFreeDate, setIsFreeDate] = useState<boolean>(false);
  const [isCollapsedCalendar, setIsCollapsedCalendar] = useState<boolean>(true);
  const [isSelectedCalendarType, setIsSelectedCalendarType] = useState<boolean>(false);

  // const formDataArray = ['start', 'insurance_type', 'reason', 'time', 'desired_date'];

  const setSelectedDesiredDateCallback = (value: string): void => {
    setSelectedDesiredTime(value);
  };

  const branchId = loadGlobalBranchId();
  // const branchId = 247148;

  const valueOptionReasons =
    fields && Object.entries(fields.reason.choices).map((b: any) => newOption(b[0], b[1]));
  const valueOptionInsurance =
    fields && Object.entries(fields.insurance_type.choices).map((b: any) => newOption(b[0], b[1]));
  const valueOptionDoctor =
    fields && Object.entries(fields.doctor.choices).map((b: any) => newOption(b[0], b[1]));

  const step1Data = selectedTimeDesired && {
    reason: registrationData.selectedReason,
    insurance_type: registrationData.selectedInsuranceType,
    doctor: registrationData.selectedDoctor?.id,
    time: selectedTimeDesired && selectedTimeDesired,
  };

  const { register, handleSubmit, control, reset } = useForm<FormDataModalType>({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      if (step1Data) {
        return step1Data;
      }
    }, [step1Data]),
  });

  useEffect(() => {
    if (registrationData.desired_date && registrationData.selectedDate) {
      setIsDesiredDate(true);
      setIsSelectedCalendarType(!isSelectedCalendarType);

      setSelectedDate(registrationData.selectedDate);
    }
  }, []);

  useEffect(() => {
    if (registrationData.selectedDate) {
      setValueTime(registrationData.selectedDate);
    }
  }, []);

  useEffect(() => {
    if (branchId) {
      dispatch(fetchRegistrationListFieldsTC(branchId));
      dispatch(getBranchInfoTC(branchId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (step1Data) {
      reset(step1Data);
    }
  }, [selectedTimeDesired]);

  const getDate = useCallback((doctorId: number): void => {
    if (registrationData.clientsData.next_week) {
      const currentWeek = registrationData.clientsData.next_week - 1;
      dispatch(
        getDateRegistrationTC(
          branchId,
          Number(registrationData.selectedReason),
          doctorId,
          currentWeek,
        ),
      );
    } else {
      dispatch(getDateRegistrationFirstRenderTC(branchId, doctorId));
    }
  }, [registrationData.clientsData.next_week]);

  const nextWeek = useCallback((currBranchId: number, week: number, reasonId?: string): void => {
    dispatch(getDateRegistrationTC(currBranchId, Number(reasonId), selectedDoctor?.id, week));
  }, [registration.next_week]);

  const prevWeek = useCallback((currBranchId: number, week: number, reasonId?: string): void => {
    dispatch(getDateRegistrationTC(currBranchId, Number(reasonId), selectedDoctor?.id, week));
  }, [registration.previous_week]);

  const oChangeStatusCalendar = useCallback((): void => {
    setIsCollapsedCalendar(!isCollapsedCalendar);
  }, [isCollapsedCalendar]);

  const onChangeCalendarType = (): void => {
    setValueTime('');
    dispatch(setSelectedDesiredTimeAC({ value: '', label: '' }));
    dispatch(setSelectedAvailableDateAC(''));
    setGeneralDate(undefined);
    setSelectedDate('');
    setIsSelectedCalendarType(!isSelectedCalendarType);
    setIsDesiredDate(!isDesiredDate);
  };

  const setDesiredDateCallback = useCallback((value: boolean): void => {
    setIsDesiredDate(value);
    setIsSelectedDesiredTime(value);
  }, [isDesiredDate, isSelectedDesiredTime]);

  const setFreeDateCallback = useCallback((value: boolean): void => {
    setIsFreeDate(value);
  }, [isFreeDate]);

  const setInsuranceType = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    dispatch(setSelectedInsuranceTypeAC(event.currentTarget.value));
  }, [registrationData.selectedInsuranceType]);

  const setReason = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    dispatch(setSelectedDoctorAC({} as DoctorType));
    dispatch(setSelectedReasonTypeAC(event.currentTarget.value));
  }, [registrationData.selectedDoctor, registrationData.selectedReason]);

  const setValueCallback = useCallback((date: string, start: string): void => {
    setValueTime(date);
    dispatch(setDataStep1(branchId, ''));
    dispatch(setSelectedDesiredTimeAC({ value: start, label: start }));
  }, [valueTime]);

  const setSelectedAvailableDate = useCallback((date: string): void => {
    dispatch(setSelectedAvailableDateAC(date));
  }, [registrationData.selectedAvailableDate]);

  const setSelectedDesiredDate = useCallback((date: string): void => {
    dispatch(setSelectedDesiredDateAC(date));
    setSelectedDate(date);
  }, [registrationData.selectedTimeDesiredDate, selectedDate]);

  const setChoicesTime = useCallback((choices: Array<{ value: string; label: string }>): void => {
    dispatch(setChoicesTimeAC(choices));
  }, [registrationData.choicesTime]);

  const setSelectedTime = useCallback((time: { value: string; label: string }): void => {
    dispatch(setSelectedDesiredTimeAC(time));
  }, [registrationData.selectedDesiredTime]);

  const setTimeDefaultValueCallback = useCallback((value: { value: string; label: string }): void => {
    dispatch(setSelectedTimeDesiredDateAC(value));
  }, [registrationData.selectedTimeDesiredDate]);

  const getDoctorInfo = useCallback((currBranchId: number, reasonId: number): void => {
    setSelectedDate('');
    setValueTime('');
    dispatch(getDoctorInfoTC(currBranchId, reasonId));
  }, [selectedDate, valueTime]);

  const setSelectedDoctor = (doctor: DoctorType | null): void => {
    setValueTime('');
    setSelectedDate('');
    setGeneralDate(new Date());
    setSelectedDesiredTime('');
    setIsSelectedDesiredTime(false);
    setIsDesiredDate(false);

    dispatch(setSelectedTimeDesiredDateAC({ value: '', label: '' }));
    dispatch(setSelectedDesiredTimeAC({ value: '', label: '' }));
    dispatch(setSelectedDesiredDateAC(dayjs(new Date()).format('YYYY-MM-DD')));

    dispatch(setSelectedDoctorAC(doctor));
  };

  const stateSelectedFields = setStateSelectedFields(
    registrationData.branchInfo.free_date,
    isFreeDate,
    registrationData.selectedInsuranceType,
    valueTime,
    registrationData.selectedDate,
    selectedDate,
    isDesiredDate,
    selectedDesiredTime,
    isSelectedDesiredTime,
    !!selectedDoctor?.id,
  );

  const setClassNameBtn = stateSelectedFields.setClassNameBtn;
  const setDisabledBtn = stateSelectedFields.setDisabledBtn;

  const onSubmit: SubmitHandler<FormDataModalType> = (data) => {
    if (
      registrationData.branchInfo.desired_date &&
      isDesiredDate &&
      !registrationData.branchInfo.free_date
    ) {
      if (!registrationData.selectedReason) {
        dispatch(setDataStep1(branchId, selectedDate));
      } else {
        dispatch(setDataStep1(branchId, `${selectedDate} ${data.time.label}`));
      }
      setValueTime(data.time.label);
      dispatch(setSelectedInsuranceTypeAC(data.insurance_type));
      dispatch(setSelectedReasonTypeAC(data.reason));
      dispatch(setDesiredDateAC(true));
    } else if (registrationData.branchInfo.free_date) {
      setValueTime(data.time.label);
      dispatch(setSelectedInsuranceTypeAC(data.insurance_type));
      dispatch(setSelectedReasonTypeAC(data.reason));
      dispatch(setDataStep1(branchId, `${selectedDate} ${data.time.label}`));
    } else {
      if (!registrationData.selectedDate) {
        dispatch(setDataStep1(branchId, valueTime));
      }
      dispatch(setSelectedInsuranceTypeAC(data.insurance_type));
      dispatch(setSelectedReasonTypeAC(data.reason));
      dispatch(setDesiredDateAC(false));
    }
    navigate(`${location.pathname}${location.search}${step2Hash}`);
    setCurrentPage(step2);
  };

  return (
    <div className='online-date'>
      {/*<div className="header"></div>*/}

      <form className={'content'} onSubmit={handleSubmit(onSubmit)}>
        <VisitOptionsForm
          valueOptionInsurance={valueOptionInsurance}
          register={register}
          registrationData={registrationData}
          setInsuranceType={setInsuranceType}
          valueOptionReasons={valueOptionReasons}
          valueOptionDoctor={valueOptionDoctor}
          getDate={getDate}
          getDoctorInfo={getDoctorInfo}
          branchId={branchId}
          setReason={setReason}
          fields={fields}
          setSelectedDoctor={setSelectedDoctor}
          reset={reset}
        />

        <Calendars
          control={control}
          fields={fields}
          registrationData={registrationData}
          branchId={branchId}
          isSelectedCalendarType={isSelectedCalendarType}
          setSelectedDesiredDate={setSelectedDesiredDate}
          setDesiredDateCallback={setDesiredDateCallback}
          prevWeek={prevWeek}
          nextWeek={nextWeek}
          registration={registration}
          setSelectedDesiredDateCallback={setSelectedDesiredDateCallback}
          generalDate={generalDate}
          setGeneralDate={setGeneralDate}
          valueTime={valueTime}
          setTimeDefaultValueCallback={setTimeDefaultValueCallback}
          selectedTimeDesired={selectedTimeDesired}
          setChoicesTime={setChoicesTime}
          setSelectedTime={setSelectedTime}
          setSelectedDate={setSelectedDate}
          setFreeDateCallback={setFreeDateCallback}
          isCollapsedCalendar={isCollapsedCalendar}
          setIsDesiredDate={setIsDesiredDate}
          setValueCallback={setValueCallback}
          setSelectedAvailableDate={setSelectedAvailableDate}
          oChangeStatusCalendar={oChangeStatusCalendar}
          onChangeCalendarType={onChangeCalendarType}
          setClassNameBtn={setClassNameBtn}
          setDisabledBtn={setDisabledBtn}
          status={status}
        />
      </form>
    </div>
  );
});
