import './SuccessPage.scss';
import React, { FC } from 'react';
import { baseURL_WIDGET } from '../../utils/BaseURL';

type SuccessType = {
  textSuccess?: string;
  setCurrentPage: (value: string) => void;
};

const SuccessPage: FC<SuccessType> = ({ textSuccess }) => {
  return (
    <div className="main">
      <div className="main__content">
        <img
          src={`${baseURL_WIDGET}static/media/img-send.970c664219b71fffb08c210874221a95.svg`}
          className="main__content-img"
        />
        <h1 className="main__content-title">Vielen Dank!</h1>
        <span className="main__content-subtitle">{textSuccess}</span>
        <div className="main__content_block-btn">
          <div className="main__content-btn"></div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;