/* eslint-disable */
import { useState } from 'react';

export const setValidationError = (errorState: string, formDataArray: string[]) => {
  const errorFieldKey = Object.keys(errorState);
  const matched = formDataArray.filter((el) => errorFieldKey.indexOf(el) > -1);
  const testErrorField = formDataArray.filter(
    (field) => field === matched.filter((el) => el === field)[0],
  );
  const testErrorMessage = Object.entries(errorState);
  const testErrorFieldMessage = testErrorMessage.filter(
    (mes) => mes[0] === testErrorField.filter((el) => el === mes[0])[0],
  );

  return {
    testErrorField,
    testErrorFieldMessage,
  };
};

export const useErrorHandler = (fieldName: string, errorState: string, formDataArray: string[]) => {
  const [error, setError] = useState<string | null>(null);

  const errorField = setValidationError(errorState, formDataArray).testErrorField;
  const errorFieldMessage = setValidationError(errorState, formDataArray).testErrorFieldMessage;
  const errorCurrentField = errorField.filter((el) => el === fieldName)[0];
  const errorMessageCurrentField = errorFieldMessage.filter((el) => el[0] === fieldName)[0];

  const onFieldClick = () => setError(null);
  const setErrorCallback = () => setError(errorCurrentField);

  return {
    error,
    errorMessageCurrentField,
    onFieldClick,
    setErrorCallback,
  };
};

export type ValidationErrorType = {
  error: string | null,
  errorMessageCurrentField: string[]
  onFieldClick: () => void
  setErrorCallback: () => void
}