/* eslint-disable */
export const newOption = (value: string, label: string) => {
  return {
    value,
    label,
  };
};

export const defaultChoices = (allChoices: any, currentReasonString: any) => {
  return allChoices.filter((el: any) => currentReasonString.indexOf(el.value) > -1);
};
export const defaultChoicesSelect = (allChoices: any, currentReasonString: any) => {
  return allChoices.filter((el: any) => el.value === currentReasonString);
};

export const customStyles = {
  control: (base: any) => ({
    ...base,
    width: '100%',
    minHeight: '64px',
    border: '2px solid #D6D8E7',
    borderRadius: '16px',
    boxShadow: 'none',
    '&:hover': {
      border: ` 2px solid #2a2a30`,
      backgroundColor: `#ffffff`,
    },
  }),
  placeholder: (base: any) => ({
    ...base,
    position: 'relative',
    // top: "-12px",
    left: '-10px',
    margin: '8px',
  }),
  input: (base: any) => ({
    ...base,
    width: '200px',
    // margin: "8px 8px 8px 60px",
    margin: '8px 8px 8px 0px',
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    width: '100%',
    height: '100%',
    '&:hover': {
      paddingTop: '-10px',
      cursor: 'pointer',
    },
  }),
  valueContainer: (base: any) => ({
    ...base,
    position: 'relative',
    top: '12px',
    left: '14px',
    width: '100%',
    height: '100%',
  }),
};

export const customStylesMulti = {
  control: (base: any) => ({
    ...base,
    width: '100%',
    minHeight: '64px',
    border: '2px solid #D6D8E7',
    borderRadius: '16px',
    boxShadow: 'none',
    '&:hover': {
      border: ` 2px solid #2a2a30`,
      backgroundColor: `#ffffff`,
    },
  }),
  placeholder: (base: any) => ({
    ...base,
    position: 'relative',
    top: '-5px',
    left: '-10px',
    margin: '8px',
  }),
  input: (base: any) => ({
    ...base,
    width: '200px',
    // margin: "8px 8px 8px 60px",
    margin: '8px 8px 8px 0px',
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    width: '100%',
    height: '100%',
    '&:hover': {
      paddingTop: '-10px',
      cursor: 'pointer',
    },
  }),
  valueContainer: (base: any) => ({
    ...base,
    position: 'relative',
    // top: "12px",
    left: '14px',
    width: '100%',
    height: '100%',
    paddingTop: '8px',
  }),
};

export const customStylesError = {
  control: (base: any) => ({
    ...base,
    width: '100%',
    minHeight: '64px',
    border: '2px solid #ed2e7e',
    borderRadius: '16px',
    boxShadow: 'none',
    backgroundColor: `#fff3f8`,
    '&:hover': {
      border: ` 2px solid #2a2a30`,
      backgroundColor: `#ffffff`,
    },
  }),
  placeholder: (base: any) => ({
    ...base,
    position: 'relative',
    top: '0px',
    left: '-10px',
    margin: '8px',
    color: '#c30052',
  }),
  input: (base: any) => ({
    ...base,
    width: '200px',
    margin: '8px 8px 8px 60px',
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    width: '100%',
    height: '100%',
    '&:hover': {
      paddingTop: '-10px',
      background: 'red',
    },
  }),
  valueContainer: (base: any) => ({
    ...base,
    position: 'relative',
    // top: "12px",
    left: '18px',
    width: '100%',
    height: '100%',
  }),
};

export const customStylesTime = {
  control: (base: any) => ({
    ...base,
    width: '100%',
    minHeight: '64px',
    border: '2px solid #D6D8E7',
    borderRadius: '16px',
    boxShadow: 'none',
    '&:hover': {
      border: ` 2px solid #2a2a30`,
      backgroundColor: `#ffffff`,
    },
  }),
  placeholder: (base: any) => ({
    ...base,
    position: 'relative',
    // top: "-12px",
    left: '-10px',
    margin: '8px',
  }),
  input: (base: any) => ({
    ...base,
    width: '200px',
    margin: '8px 8px 8px 0px',
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    width: '100%',
    height: '100%',
    '&:hover': {
      paddingTop: '-10px',
      cursor: 'pointer',
    },
  }),
  valueContainer: (base: any) => ({
    ...base,
    position: 'relative',
    top: '12px',
    left: '14px',
    width: '100%',
    height: '100%',
  }),

  menu: (base: any) => ({
    ...base,
    minHeight: '100px',
    maxHeight: '150px',
    width: '20%',
    marginLeft: '80%',

    '@media only screen and (max-width: 400px)': {
      ...base['@media only screen and (max-width: 400px)'],
      width: '50%',
      marginLeft: '50%',
    },
  }),

  menuList: (base: any) => ({
    ...base,

    minHeight: '100px',
    maxHeight: '150px',
    '::-webkit-scrollbar': {
      // width: "4px",
      // height: "0px",
      display: 'none',
    },

    // "::-webkit-scrollbar-track": {
    //     background: "#f1f1f1"
    // },
    // "::-webkit-scrollbar-thumb": {
    //     background: "#888"
    // },
    // "::-webkit-scrollbar-thumb:hover": {
    //     background: "#555"
    // }
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    // backgroundColor: `red`,
    margin: '0 auto',
    padding: '6px 10px',
    borderRadius: '8px',
    width: '60px',
    '&:hover': {
      backgroundColor: `#f0f0f0`,
    },
    backgroundColor: state.isSelected ? '#49cd6e' : '#fff',
  }),
};