import './Inputs.scss';
import {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Path } from 'react-hook-form';
import { FormDataType } from './URLInput';
import { CalendarGeneral } from '../Calendar/Calendar';
import { baseURL_WIDGET } from '../../utils/BaseURL';

type DefaultInputPropsType = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type InputsNewType = DefaultInputPropsType & {
  onChangeText?: (value: string) => void;
  onEnter?: () => void;
  order?: string;
  error?: string | null;
  spanClassName?: string;
  maxLength?: any;
  label?: any;
  register?: any; //UseFormRegister<FormDataType>;
  resetForm?: () => void;
  state?: string;
  name?: Path<FormDataType> | string;
  // input_name: Path<FormDataType>;
  help_text?: string | null;
  img?: string;
  input_type?: string;
  defaultValue?: any;
};

const InputsNew: FC<InputsNewType> = ({
  step,
  onChange,
  onChangeText,
  error,
  maxLength,
  name,
  state,
  img,
  input_type,
  defaultValue,
  help_text,
  label,
  register,
  ...restProps
}) => {
  const componentRef = useRef();

  const [focused, setFocus] = useState(false);
  const [value, setValue] = useState(defaultValue || '');
  const [labels, deleteLabel] = useState(true);
  const [openCalendarGeneral, setOpenCalendarGeneral] = useState<boolean>(false);

  useEffect(() => {
    const handleClick = (e: any): void => {
      if (componentRef && componentRef.current) {
        const ref: any = componentRef.current;
        if (!ref.contains(e.target)) {
          setOpenCalendarGeneral(false);
        }
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  const onFocusHandler = (): void => {
    setFocus(true);
  };

  const onClickHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    deleteLabel(false);
    e.preventDefault();
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange && onChange(e);
    onChangeText && onChangeText(e.currentTarget.value);
    setValue(e.target.value);
  };

  const customInputClass = ` 
      fieldTerminow_${img} 
      ${focused ? 'fieldTerminow_focus' : 'fieldTerminow_unfocus'} 
      ${
        state === 'order'
          ? `order fieldTerminow_${state}`
          : ` fieldTerminow fieldTerminow_${state}  `
      }
      ${error ? 'fieldTerminow_error ' : ''}
      ${value.length ? 'fieldTerminow_focus' : 'fieldTerminow_unfocus'}
      ${defaultValue ? 'fieldTerminow_focus' : 'fieldTerminow_unfocus'}
 `;

  const testRegister = register(name, {
    onBlur: () => {
      if (value !== '') {
        setFocus(false);
      } else {
        setFocus(false);
        deleteLabel(true);
      }
    },
    onChange: onChangeHandler,
  });

  return (
    <div ref={componentRef as any}>
      <div className={customInputClass}>
        <input
          className="fieldTerminow__input"
          {...(register && {
            ...testRegister,
          })}
          maxLength={maxLength}
          id={`id_${name}`}
          onFocus={onFocusHandler}
          onClick={onClickHandler}
          value={value}
          type={input_type}
          disabled={state === 'disabled'}
          step={step}
          max={state === 'date' ? '9999-12-31' : ''}
          {...restProps}
        />
        <label
          className={labels ? 'fieldTerminow__label' : 'fieldTerminow__labels'}
          htmlFor={`id_${name}`}
        >
          {label}
        </label>
        <span className="fieldTerminow__help-text">{help_text}</span>

        <img
          onClick={() => {
            setOpenCalendarGeneral(!openCalendarGeneral);
          }}
          src={`${baseURL_WIDGET}static/media/calendar.38ea55e5d97b494fc259c58a7b9fc990.svg`}
          alt="date"
          className={
            state === 'date' || state === 'datetime'
              ? 'fieldTerminow__img'
              : 'fieldTerminow__imgNone'
          }
        />

        {openCalendarGeneral && (
          <div
            onClick={() => {
              setOpenCalendarGeneral(false);
            }}
            className="calendar"
          >
            <CalendarGeneral
              isCalendar={false}
              onDayClickCustom={(date: string) => {
                onChangeText && onChangeText(date);
                setValue(date);
                testRegister.onChange({ target: { value: date, name: name } });
                setOpenCalendarGeneral(false); //
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default InputsNew;
