/* eslint-disable */
import "./InputsWidgets.scss"
import React from "react";
import {
    ChangeEvent,
    DetailedHTMLProps,
    FC,
    InputHTMLAttributes,
    useState
} from "react";
import { Path } from "react-hook-form";
import { FormDataType } from './URLInput';

type DefaultInputPropsType = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

type InputsNewTypeW = DefaultInputPropsType & {
    onChangeText?: (value: string) => void
    onInputClick?:()=>void
    onEnter?: () => void
    order?: string
    error?: string | null
    spanClassName?: string
    maxLength?: any
    label?: any
    register?: any  //UseFormRegister<FormDataType>;
    resetForm?: () => void
    state?: string
    name?: Path<FormDataType> | string
    help_text?: string | null
    img?: string
    input_type?: string
    defaultValue?: any
    value?: string
};

const InputsWidgets: FC<InputsNewTypeW> = ({
    type, step,
    onChange, onChangeText,
    onKeyPress, onEnter,onInputClick,
    error, order, maxLength,
    className, spanClassName,
    name, state, img,
    input_type, defaultValue,
    help_text,
    value,
    label, register, resetForm, onClick,
    ...restProps
}) => {

    const [focused, setFocus] = useState(false);

    // const [value, setValue] = useState(defaultValue || '');
    const [labels] = useState(true)

    const onFocusHandler = (): void => {
        setFocus(true);
    }

    const onClickHandler = (): void => {
        onInputClick && onInputClick()
    }

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
        onChange && onChange(e)
        onChangeText && onChangeText(e.currentTarget.value)
    }

    const customInputClass1 = ` 
      fieldW_${img} 
      ${focused ? "fieldW_focus" : "fieldW_unfocus"} 
      ${state === 'order' ? `order fieldW_${state}` : ` fieldW fieldW_${state}  `}
      ${error ? "fieldW_error " : ''}
      ${value?.length ? "fieldW_focus" : "fieldW_unfocus"}
      ${defaultValue ? "fieldW_focus" : "fieldW_unfocus"}
 `

    return (
        <div
            className={customInputClass1}>
            <input className="fieldW__input"
                defaultValue={defaultValue}
                name={name}
                onChange={onChangeHandler}
                maxLength={maxLength}
                max={'2999-12-31'}
                id={`id_${name}`}
                onFocus={onFocusHandler}
                onClick={onClickHandler}
                type={input_type}
                disabled={state === "disabled"}
                value={value}
                step={step}
                {...restProps} />

            <label 
            className={labels ? "fieldW__label" : 'fieldW__labels'}
                htmlFor={`id_${name}`}>
                {label}
            </label>
            <span className="fieldW__help-text">{help_text}</span>
        </div>

    );
}

export default InputsWidgets;
