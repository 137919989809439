import React, { FC, useEffect, useState } from 'react';
// import './App.scss';
import './pages/OnlineRegistration/Step1/Step1.scss';
import '../src/assets/fonts/poppins/style.css';
import { Step1 } from './pages/OnlineRegistration/Step1/Step1';
import SuccessPage from './pages/SuccessPage/SuccessPage';
import { Step2 } from './pages/OnlineRegistration/Step2/Step2';
import { useLocation } from 'react-router-dom';

const App: FC = () => {
  const step1 = 'Step-1';
  const step2 = 'Step-2';
  const successPage = 'Success_page';
  const step2Hash = '#step2';

  const [currentPage, setCurrentPage] = useState(step1);

  const location = useLocation();
  const hash = location.hash;

  useEffect(() => {
    if (currentPage !== successPage) {
      if (hash !== step2Hash) {
        setCurrentPage(step1);
      } else {
        setCurrentPage(step2);
      }
    }
  });

  if (currentPage === successPage) {
    return (
      <SuccessPage
        textSuccess="Ihre Terminanfrage wurde erfolgreich übermittelt"
        setCurrentPage={setCurrentPage}
      />
    );
  }

  return (
    <>
      {currentPage === step1 && (
        <Step1 setCurrentPage={setCurrentPage} step2={step2} step2Hash={step2Hash} />
      )}

      {currentPage === step2 && hash === step2Hash && (
        <Step2 setCurrentPage={setCurrentPage} step1={step1} successPage={successPage} />
      )}
    </>
  );
};

export default App;
