import { baseURL_WIDGET } from '../../../../utils/BaseURL';
import React, { FC } from 'react';
import { InitialStateType } from '../../../../redux/registration-reducer';
import { DoctorType } from '../../../../api/registration-api';
import './../Step2.scss';

type VisitInfoPropsType = {
  photoDoctorUrl: string;
  registrationData: InitialStateType;
  doctorInfo: DoctorType | null;
  dateVisitDisplay: string;
};

export const VisitInfo: FC<VisitInfoPropsType> = ({
  photoDoctorUrl,
  registrationData,
  doctorInfo,
  dateVisitDisplay,
}) => {
  return (
    <div className="doctor">
      <div className="doctor__info">
        <div className="doctor__text">
          <img alt="doctor" className="doctor__text-photo" src={photoDoctorUrl} />

          <div className="doctor__text-wrapper">
            <h4 className="doctor__text-wrapper-titleBranch">{registrationData.branchInfo.name}</h4>
            <span className="doctor__text-wrapper-name">
              {doctorInfo && doctorInfo.first_name} {doctorInfo && doctorInfo.last_name}
            </span>

            <div className="doctor__text-location">
              <span>
                {registrationData.branchInfo.street} {registrationData.branchInfo.street_number}
              </span>
              <br />
              <span>
                {registrationData.branchInfo.zip_code} {registrationData.branchInfo.sity}
              </span>
            </div>
          </div>
        </div>
        <div className="doctor__date">
          <img
            src={`${baseURL_WIDGET}static/media/times.9edc2ac8903d690de0b4e24a645a2a88.svg`}
            className="doctor__date-img"
            alt={'time'}
          />
          <div className="doctor__date-value">
            Ihr Termin: <span className="doctor__date-day">{dateVisitDisplay}</span>
          </div>
        </div>
      </div>
      <div className="items">
        <div className="items__text">Verschlüsselte Datenübertragung</div>
        <div className="items__text">Sie erhalten keine Werbung</div>
        <div className="items__text">Wir erinnern Sie an Ihren Termin</div>
      </div>
    </div>
  );
};