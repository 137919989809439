/* eslint-disable */
export const setStateSelectedFields = (
  free_date: boolean, isFreeDate: boolean, selectedInsuranceType: string | undefined,
  valueTime: string, registrationSelectedDate: string | undefined, selectedDate: string, isDesiredDate: boolean,
  selectedDesiredTime: any, isSelectedDesiredTime: boolean, selectedDoctor: boolean,
) => {

  const setClassNameBtn = () => {
    // if (free_date && isFreeDate && (selectedInsuranceType)) {
    //     return 'button button_size-big  button_color-green client-btn'
    // }
    //
    // if (((!valueTime && !selectedDate) && !isDesiredDate) || (!selectedInsuranceType)) {
    //     return 'button button_size-big  button_color-disabled client-btn'
    // }
    //
    // if (!isDesiredDate) {
    //     if ((valueTime || isDesiredDate) && (selectedInsuranceType)) {
    //         return 'button button_size-big  button_color-green client-btn'
    //     }
    // } else {
    //     if ((valueTime && isDesiredDate) && (selectedInsuranceType) && isSelectedDesiredTime) {
    //         return 'button button_size-big  button_color-green client-btn'
    //     }
    //     if ((isDesiredDate) && (selectedInsuranceType) && isSelectedDesiredTime) {
    //         return 'button button_size-big  button_color-green client-btn'
    //     }
    // }
    //
    // if ((!selectedDesiredTime || !isSelectedDesiredTime)  || (!selectedInsuranceType)) {
    //     return 'button button_size-big  button_color-disabled client-btn'
    // }
    //
    // if (!isSelectedDesiredTime || !selectedInsuranceType) {
    //     return 'button button_size-big  button_color-disabled client-btn'
    // }
    // if ((selectedDate && (isDesiredDate) && isSelectedDesiredTime) && (selectedInsuranceType)) {
    //     return 'button button_size-big  button_color-green client-btn'
    // }

    if (free_date && isFreeDate && (selectedInsuranceType && selectedDoctor)) {
      return 'button button_size-big  button_color-green client-btn';
    }

    if (((!valueTime && !selectedDate) && !isDesiredDate) || (!selectedInsuranceType && !selectedDoctor)) {
      return 'button button_size-big  button_color-disabled client-btn';
    }

    if (!isDesiredDate) {
      if ((valueTime || isDesiredDate) && (selectedInsuranceType && selectedDoctor)) {
        return 'button button_size-big  button_color-green client-btn';
      }
    } else {
      if ((valueTime && isDesiredDate) && (selectedInsuranceType && selectedDoctor) && isSelectedDesiredTime) {
        return 'button button_size-big  button_color-green client-btn';
      }
      if ((isDesiredDate) && (selectedInsuranceType && selectedDoctor) && isSelectedDesiredTime) {
        return 'button button_size-big  button_color-green client-btn';
      }
    }

    if ((!selectedDesiredTime || !isSelectedDesiredTime) || (!selectedInsuranceType && !selectedDoctor)) {
      return 'button button_size-big  button_color-disabled client-btn';
    }

    if (!isSelectedDesiredTime || !selectedInsuranceType || !selectedDoctor) {
      return 'button button_size-big  button_color-disabled client-btn';
    }
    if ((selectedDate && (isDesiredDate) && isSelectedDesiredTime) && (selectedInsuranceType && selectedDoctor)) {
      return 'button button_size-big  button_color-green client-btn';
    }
  };

  const setDisabledBtn = () => {
    // if (free_date && isFreeDate && (selectedInsuranceType)) {
    //     return false
    // }
    //
    // if (((!valueTime && !selectedDate) && !isDesiredDate) || (!selectedInsuranceType)) {
    //     return true
    // }
    //
    // if (!isDesiredDate) {
    //     if ((valueTime || isDesiredDate) && (selectedInsuranceType)) {
    //         return false
    //     }
    // } else {
    //     if ((valueTime && isDesiredDate) && (selectedInsuranceType)) {
    //         return false
    //     }
    // }
    //
    // if ((!selectedDesiredTime && !isSelectedDesiredTime) || (!selectedInsuranceType)) {
    //     return true
    // }
    //
    // if (!isSelectedDesiredTime || (!selectedInsuranceType)) {
    //     return true
    // }
    //
    // if ((selectedDate && (isDesiredDate)) || (selectedInsuranceType)) {
    //     return false
    // }

    if (free_date && isFreeDate && (selectedInsuranceType && selectedDoctor)) {
      return false;
    }

    if (((!valueTime && !selectedDate) && !isDesiredDate) || (!selectedInsuranceType && !selectedDoctor)) {
      return true;
    }

    if (!isDesiredDate) {
      if ((valueTime || isDesiredDate) && (selectedInsuranceType && selectedDoctor)) {
        return false;
      }
    } else {
      if ((valueTime && isDesiredDate) && (selectedInsuranceType && selectedDoctor) && isSelectedDesiredTime) {
        return false;
      }
      if ((isDesiredDate) && (selectedInsuranceType && selectedDoctor) && isSelectedDesiredTime) {
        return false;
      }
    }

    if ((!selectedDesiredTime || !isSelectedDesiredTime) || (!selectedInsuranceType && !selectedDoctor)) {
      return true;
    }

    if (!isSelectedDesiredTime || !selectedInsuranceType || !selectedDoctor) {
      return true;
    }
    if ((selectedDate && (isDesiredDate) && isSelectedDesiredTime) && (selectedInsuranceType && selectedDoctor)) {
      return false;
    }
  };

  return {
    setClassNameBtn,
    setDisabledBtn,
  };

};

// const setClassNameBtn = () => {
//     if (registrationData.branchInfo.free_date && isFreeDate && (registrationData.selectedInsuranceType)) {
//         return 'button button_size-big  button_color-green client-btn'
//     }
//
//     // if ((!valueTime && !isDesiredDate) || (!registrationData.selectedInsuranceType)) {
//     //     return 'button button_size-big  button_color-disabled client-btn'
//     // }
//
//     if (((!valueTime && !registrationData.selectedDate) && !isDesiredDate) || (!registrationData.selectedInsuranceType)) {
//         return 'button button_size-big  button_color-disabled client-btn'
//     }
//
//     if (!isDesiredDate) {
//         if ((valueTime || isDesiredDate) && (registrationData.selectedInsuranceType)) {
//             return 'button button_size-big  button_color-green client-btn'
//         }
//     } else {
//         if ((valueTime && isDesiredDate) && (registrationData.selectedInsuranceType)) {
//             return 'button button_size-big  button_color-green client-btn'
//         }
//     }
//     if ((!selectedDesiredTime && !isSelectedDesiredTime) || (!registrationData.selectedInsuranceType)) {
//         return 'button button_size-big  button_color-disabled client-btn'
//     }
//     if ((selectedDate && (isDesiredDate)) || (registrationData.selectedInsuranceType)) {
//         return 'button button_size-big  button_color-green client-btn'
//     }
// }

// const setDisabledBtn = () => {
//     if (registrationData.branchInfo.free_date && isFreeDate && (registrationData.selectedInsuranceType)) {
//         return false
//     }
//
//     // if ((!valueTime && !isDesiredDate) || (!registrationData.selectedInsuranceType)) {
//     //     return true
//     // }
//
//     if (((!valueTime && !registrationData.selectedDate) && !isDesiredDate) || (!registrationData.selectedInsuranceType)) {
//         return true
//     }
//
//     if (!isDesiredDate) {
//         if ((valueTime || isDesiredDate) && (registrationData.selectedInsuranceType)) {
//             return false
//         }
//     } else {
//         if ((valueTime && isDesiredDate) && (registrationData.selectedInsuranceType)) {
//             return false
//         }
//     }
//
//     if ((!selectedDesiredTime && !isSelectedDesiredTime) || (!registrationData.selectedInsuranceType)) {
//         return true
//     }
//     if ((selectedDate && (isDesiredDate)) || (registrationData.selectedInsuranceType)) {
//         return false
//     }
// }