import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const fieldsAPI = {
  getListFieldRegistration(/*token: string,*/ branchId: number | null) {
    return instance.get<RegistrationListFieldsType>(
      `/api/client/events/create/?branch=${branchId}`,
      {},
    );
  },
};

export type ModelFieldType = {
  help_text: string;
  hidden: boolean;
  initial_value: null | any;
  input_type: string;
  label: string;
  required: boolean;
  type: string;
  widget_type: string;
};
export type ModelFieldWithChoicesType = {
  choices: {};
  help_text: string;
  hidden: boolean;
  initial_value: null | string;
  input_type: string;
  label: string;
  required: boolean;
  type: string;
  widget_type: string;
};

export type RegistrationListFieldsType = {
  date_birth: ModelFieldType;
  email: ModelFieldType;
  first_name: ModelFieldType;
  gender: ModelFieldWithChoicesType;
  insurance_type: ModelFieldWithChoicesType;
  last_name: ModelFieldType;
  message: ModelFieldType;
  phone: ModelFieldType;
  reason: ModelFieldWithChoicesType;
  start: ModelFieldType;
  desired_date: ModelFieldType;
  doctor: ModelFieldWithChoicesType;
};