/* eslint-disable */
import { HolidayType, WorkScheduleInfoType } from '../../api/registration-api';
import { newOption } from '../../components/Widgets/Select/ReactSelectStyles';
import dayjs from 'dayjs';

export const filterWorkTime = (
  workSchedule: Array<WorkScheduleInfoType>,
  dayIndex: number,
  date: string,
  notFullHolidayPrivate: HolidayType[],
  notFullHolidayOfficial: HolidayType[],
  setChoicesTimeCallback: (value: Array<{ value: string; label: string }>) => void,
) => {
  const today = dayjs(new Date()).format('YYYY-MM-DD');

  const selectedWorkDate = workSchedule.find((day, index) => index === dayIndex);
  const startTime = selectedWorkDate && selectedWorkDate.start_time_work;
  const endTime = selectedWorkDate && selectedWorkDate.end_time_work;

  const isCurrentDateHolidayPrivate = notFullHolidayPrivate.some((priv) => priv.date === date);
  const isCurrentDateHolidayOfficial = notFullHolidayOfficial.some(
    (official) => official.date === date,
  );
  const isCurrentLunchBreak = workSchedule[dayIndex].lunch_break;

  const calcTime24Hour = (startTime: string, endTime: string) => {
    const startTimeNumber = Number(startTime.slice(0, 2));
    const endTimeNumber = Number(endTime.slice(0, 2));
    const editEndTimeNumber = 23.59;

    const timeWorkDefault = [startTimeNumber];
    for (let i: any = startTimeNumber; i < editEndTimeNumber; i = i + 1) {
      let edit = i;
      // edit !== startTimeNumber && timeWorkDefault.push(edit)
      // edit = i + 0.15
      // timeWorkDefault.push(edit)
      // edit = edit + 0.15
      // timeWorkDefault.push(edit)
      // edit = edit + 0.15
      // timeWorkDefault.push(edit)

      if (startTime.slice(3, 5) === '00') {
        edit !== startTimeNumber && timeWorkDefault.push(i);
        edit = i + 0.15;
        timeWorkDefault.push(edit);
        edit = edit + 0.15;
        timeWorkDefault.push(edit);
        edit = edit + 0.15;
        timeWorkDefault.push(edit);
      }

      if (startTime.slice(3, 5) === '15') {
        if (edit !== startTimeNumber) {
          timeWorkDefault.push(i - 0.15);
          timeWorkDefault.push(i);
        }

        if (i === startTimeNumber) {
          edit = i + 0.15;
          timeWorkDefault.push(edit);
          edit = edit + 0.15;
          timeWorkDefault.push(edit);
        }

        if (i !== startTimeNumber) {
          edit = i + 0.15;
          timeWorkDefault.push(edit);
          edit = edit + 0.15;
          timeWorkDefault.push(edit);
        }
      }

      if (startTime.slice(3, 5) === '30') {
        if (edit !== startTimeNumber) {
          timeWorkDefault.push(i - 0.3);
          timeWorkDefault.push(i - 0.15);
          timeWorkDefault.push(i);
        }

        if (i === startTimeNumber) {
          edit = i + 0.15;
          timeWorkDefault.push(edit);
        }

        if (i !== startTimeNumber) {
          edit = i + 0.15;
          timeWorkDefault.push(edit);
        }
      }

      if (startTime.slice(3, 5) === '45') {
        if (edit !== startTimeNumber) {
          timeWorkDefault.push(i - 0.45);
          timeWorkDefault.push(i - 0.3);
          timeWorkDefault.push(i - 0.15);
          timeWorkDefault.push(i);
        }
      }
    }
    const roundedTime = timeWorkDefault.map((hour) => hour.toFixed(2));
    const roundedTimeNumber = roundedTime.map((hour) => Number(hour));

    const timeWorkDay =
      roundedTimeNumber &&
      roundedTimeNumber.map((hour) => (Number(hour) < 10 ? `0${hour.toString()}` : `${hour}`));

    const timeWorkDayEdit =
      timeWorkDay && timeWorkDay.map((hour) => (hour.length < 4 ? `${hour}:00` : hour));

    const timeWorkDayEditNum =
      timeWorkDayEdit && timeWorkDayEdit.map((hour) => (hour.length === 4 ? `${hour}0` : hour));

    const timeWorkDayEditStart =
      timeWorkDayEditNum &&
      timeWorkDayEditNum.map((hour, index) => (index === 0 ? startTime.slice(0, 5) : hour));

    const timeWorkDayFinally =
      timeWorkDayEditStart &&
      timeWorkDayEditStart.map((hour, index) =>
        hour
          .split('')
          .map((el, index) => (index === 2 ? ':' : el))
          .join(''),
      );

    //choicesTime
    const choicesTime =
      timeWorkDayFinally &&
      Object.entries(timeWorkDayFinally).map((b: any) => newOption(`${b[1]}:00`, b[1]));

    // choicesTime && setChoicesTime(choicesTime);
    choicesTime && setChoicesTimeCallback && setChoicesTimeCallback(choicesTime);

    return {
      timeWorkDayFinally,
      choicesTime,
    };
  };

  const calcTime = (startTime: string, endTime: string) => {
    // const startTimeNumber = Number(startTime.slice(0, 2))
    // const endTimeNumber = Number(endTime.slice(0, 2))

    const startTimeNumber = Number(`${startTime.slice(0, 2)}.${startTime.slice(3, 5)}`);
    const endTimeNumber = Number(`${endTime.slice(0, 2)}.${endTime.slice(3, 5)}`);

    const timeWorkDefault = [startTimeNumber];
    for (let i: any = startTimeNumber; i < endTimeNumber; i = i + 1) {
      let edit = i;
      // edit !== startTimeNumber && timeWorkDefault.push(edit)

      if (startTime.slice(3, 5) === '00') {
        edit !== startTimeNumber && timeWorkDefault.push(i);
        edit = i + 0.15;
        timeWorkDefault.push(edit);
        edit = edit + 0.15;
        timeWorkDefault.push(edit);
        edit = edit + 0.15;
        timeWorkDefault.push(edit);
      }

      if (startTime.slice(3, 5) === '15') {
        if (edit !== startTimeNumber) {
          timeWorkDefault.push(i - 0.15);
          timeWorkDefault.push(i);
        }

        if (i === startTimeNumber) {
          edit = i + 0.15;
          timeWorkDefault.push(edit);
          edit = edit + 0.15;
          timeWorkDefault.push(edit);
        }

        if (i !== startTimeNumber) {
          if (i < endTimeNumber - 0.3) {
            edit = i + 0.15;
            timeWorkDefault.push(edit);
          }
          if (i < endTimeNumber - 0.3) {
            edit = edit + 0.15;
            timeWorkDefault.push(edit);
          }
          // edit = i + 0.15
          // timeWorkDefault.push(edit)
          // edit = edit + 0.15
          // timeWorkDefault.push(edit)
        }
      }

      if (startTime.slice(3, 5) === '30') {
        if (edit !== startTimeNumber) {
          timeWorkDefault.push(i - 0.3);
          timeWorkDefault.push(i - 0.15);
          timeWorkDefault.push(i);
        }

        if (i === startTimeNumber) {
          edit = i + 0.15;
          timeWorkDefault.push(edit);
        }

        if (i !== startTimeNumber) {
          edit = i + 0.15;
          timeWorkDefault.push(edit);
        }
      }

      if (startTime.slice(3, 5) === '45') {
        if (edit !== startTimeNumber) {
          timeWorkDefault.push(i - 0.45);
          timeWorkDefault.push(i - 0.3);
          timeWorkDefault.push(i - 0.15);
          timeWorkDefault.push(i);
        }
      }
      // edit = i + 0.15
      // timeWorkDefault.push(edit)
      //
      // edit = edit + 0.15
      // timeWorkDefault.push(edit)
      //
      // edit = edit + 0.15
      // timeWorkDefault.push(edit)
    }

    const roundedTime = timeWorkDefault.map((hour) => hour.toFixed(2));
    const roundedTimeNumber = roundedTime.map((hour) => Number(hour));

    if (
      (isCurrentLunchBreak && (isCurrentDateHolidayPrivate || isCurrentDateHolidayOfficial)) ||
      isCurrentLunchBreak ||
      isCurrentDateHolidayPrivate ||
      isCurrentDateHolidayOfficial
    ) {
      //handling holiday
      const startHolidayPrivateCurrentDate = notFullHolidayPrivate
        .find((hol) => hol.date === date)
        ?.start_time.slice(0, 5);
      const endHolidayPrivateCurrentDate = notFullHolidayPrivate
        .find((hol) => hol.date === date)
        ?.end_time.slice(0, 5);

      const startHolidayOfficialCurrentDate = notFullHolidayOfficial
        .find((hol) => hol.date === date)
        ?.start_time.slice(0, 5);
      const endHolidayOfficialCurrentDate = notFullHolidayOfficial
        .find((hol) => hol.date === date)
        ?.end_time.slice(0, 5);

      const startHolidayPrivateNumber =
        startHolidayPrivateCurrentDate &&
        Number(
          startHolidayPrivateCurrentDate
            .split('')
            .map((el, index) => (index === 2 ? '.' : el))
            .join(''),
        );

      const endHolidayPrivateNumber =
        endHolidayPrivateCurrentDate &&
        Number(
          endHolidayPrivateCurrentDate
            .split('')
            .map((el, index) => (index === 2 ? '.' : el))
            .join(''),
        );

      const startHolidayOfficialNumber =
        startHolidayOfficialCurrentDate &&
        Number(
          startHolidayOfficialCurrentDate
            .split('')
            .map((el, index) => (index === 2 ? '.' : el))
            .join(''),
        );

      const endHolidayOfficialNumber =
        endHolidayOfficialCurrentDate &&
        Number(
          endHolidayOfficialCurrentDate
            .split('')
            .map((el, index) => (index === 2 ? '.' : el))
            .join(''),
        );

      //handling lunch
      const startLunch = selectedWorkDate && selectedWorkDate.start_lunch_break.slice(0, 5);
      const endLunch = selectedWorkDate && selectedWorkDate.end_lunch_break.slice(0, 5);

      const startLunchNumber =
        startLunch &&
        Number(
          startLunch
            .split('')
            .map((el, index) => (index === 2 ? '.' : el))
            .join(''),
        );

      const endLunchNumber =
        endLunch &&
        Number(
          endLunch
            .split('')
            .map((el, index) => (index === 2 ? '.' : el))
            .join(''),
        );

      const workTimeWithoutLunch =
        startLunchNumber &&
        endLunchNumber &&
        roundedTimeNumber.filter((hour) => hour < startLunchNumber || hour >= endLunchNumber);

      //handling general time
      const setGeneralFilterTime = () => {
        if (
          isCurrentDateHolidayPrivate &&
          startHolidayPrivateNumber &&
          endHolidayPrivateNumber &&
          workTimeWithoutLunch
        ) {
          return workTimeWithoutLunch.filter(
            (hour) => hour < startHolidayPrivateNumber || hour >= endHolidayPrivateNumber,
          );
        }
        if (
          isCurrentDateHolidayOfficial &&
          startHolidayOfficialNumber &&
          endHolidayOfficialNumber &&
          workTimeWithoutLunch
        ) {
          return workTimeWithoutLunch.filter(
            (hour) => hour < startHolidayOfficialNumber || hour >= endHolidayOfficialNumber,
          );
        }
        if (
          !isCurrentDateHolidayPrivate &&
          !isCurrentDateHolidayOfficial &&
          startLunchNumber &&
          endLunchNumber
        ) {
          return roundedTimeNumber.filter(
            (hour) => hour < startLunchNumber || hour >= endLunchNumber,
          );
        }
      };

      const generalFilterTime = setGeneralFilterTime();

      const timeWorkDay =
        generalFilterTime &&
        generalFilterTime.map((hour) => (Number(hour) < 10 ? `0${hour.toString()}` : `${hour}`));

      //
      const timeWorkDayEdit =
        timeWorkDay && timeWorkDay.map((hour) => (hour.length < 4 ? `${hour}:00` : hour));

      const timeWorkDayEditNum =
        timeWorkDayEdit && timeWorkDayEdit.map((hour) => (hour.length === 4 ? `${hour}0` : hour));

      const timeWorkDayEditStart =
        timeWorkDayEditNum &&
        timeWorkDayEditNum.map((hour, index) => (index === 0 ? startTime.slice(0, 5) : hour));

      const timeWorkDayFinally =
        timeWorkDayEditStart &&
        timeWorkDayEditStart.map((hour, index) =>
          hour
            .split('')
            .map((el, index) => (index === 2 ? ':' : el))
            .join(''),
        );

      //choicesTime
      let choicesTime =
        timeWorkDayFinally &&
        Object.entries(timeWorkDayFinally).map((b: any) => newOption(`${b[1]}:00`, b[1]));

      // choicesTime && setChoicesTime(choicesTime);

      if (date === today) {
        const currentTime = dayjs(new Date()).format('HH:mm:ss');
        choicesTime = choicesTime && choicesTime.filter((time) => time.value > currentTime);
      }

      choicesTime && setChoicesTimeCallback && setChoicesTimeCallback(choicesTime);

      return {
        timeWorkDayFinally,
        choicesTime,
      };
    } else {
      const timeWorkFormatHour =
        roundedTimeNumber &&
        roundedTimeNumber.map((hour) => (Number(hour) < 10 ? `0${hour.toString()}` : `${hour}`));

      const timeWorkFormatMinutes =
        timeWorkFormatHour &&
        timeWorkFormatHour.map((hour) => (hour.length < 4 ? `${hour}:00` : hour));

      const timeWorkFormatMinutesLengthFour =
        timeWorkFormatMinutes &&
        timeWorkFormatMinutes.map((hour) => (hour.length === 4 ? `${hour}0` : hour));

      const timeWorkFormatStart =
        timeWorkFormatMinutesLengthFour &&
        timeWorkFormatMinutesLengthFour.map((hour, index) =>
          index === 0 ? startTime.slice(0, 5) : hour,
        );

      const timeWorkFormatFinally =
        timeWorkFormatStart &&
        timeWorkFormatStart.map((hour, index) =>
          hour
            .split('')
            .map((el, index) => (index === 2 ? ':' : el))
            .join(''),
        );

      //choicesTime
      let choicesTime =
        timeWorkFormatFinally &&
        Object.entries(timeWorkFormatFinally).map((b: any) => newOption(`${b[1]}:00`, b[1]));
      // choicesTime && setChoicesTime(choicesTime);

      if (date === today) {
        const currentTime = dayjs(new Date()).format('HH:mm:ss');
        choicesTime = choicesTime && choicesTime.filter((time) => time.value > currentTime);
      }

      choicesTime && setChoicesTimeCallback && setChoicesTimeCallback(choicesTime);

      return {
        timeWorkFormatFinally,
        choicesTime,
      };
    }
  };

  return (
    startTime &&
    endTime && {
      selectedDate: selectedWorkDate,
      calcTime:
        endTime === '00:00:00' ? calcTime24Hour(startTime, endTime) : calcTime(startTime, endTime),
    }
  );
};