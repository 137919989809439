import { Dispatch } from 'redux';
import { setAppErrorAC, setAppStatusAC } from '../redux/app-reducer';

export const handleServerAppError = (error: any, dispatch: Dispatch<any>): void => {
  if (error.response.status !== 404 && error.response.status !== 500) {
    dispatch(setAppErrorAC(error.response.data));
  } else {
    dispatch(setAppErrorAC(error.message));
  }

  dispatch(setAppStatusAC('failed'));
  console.log(error.message);
};

export const handleServerNetworkError = (error: any, dispatch: Dispatch<any>): void => {
  if (error.message === 'Network Error') {
    // alert(error.message)
    // console.log(error.message)
    dispatch(setAppErrorAC(error.message));
  } else {
    // console.log(error.message)
  }
  dispatch(setAppStatusAC('failed'));
};
