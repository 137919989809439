import { AppRootStateType } from '../store';
import { DataOfClientsType, DateInfoOfClientType, DoctorType } from '../../api/registration-api';
import { InitialStateType } from '../registration-reducer';

export const getRegistrationSelector = (state: AppRootStateType): DataOfClientsType =>
  state.registration.clientsData;

export const getRegistrationDataSelector = (state: AppRootStateType): InitialStateType =>
  state.registration;

export const getSelectedTimeDesiredSelector = (
  state: AppRootStateType,
): { value: string; label: string } => state.registration.selectedTimeDesiredDate;

export const getChosenDaySelector = (state: AppRootStateType): { date: string; time: string } =>
  state.registration.chosenDayInSchedule;

export const getReasonBranchInfoSelector = (state: AppRootStateType): DateInfoOfClientType =>
  state.registration.branchInfo;

export const getDoctorsInfoSelector = (state: AppRootStateType): Array<DoctorType> =>
  state.registration.doctors;

export const getSelectedDoctorSelector = (state: AppRootStateType): DoctorType | null =>
  state.registration.selectedDoctor;