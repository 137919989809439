import React, { FC, useEffect } from 'react';
import { CalendarGeneral } from '../../../../../components/Calendar/Calendar';
import { ReactSelect } from '../../../../../components/Widgets/Select/ReactSelect';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { filterWorkTime } from '../../../../../utils/CalendarDesiredDate/filterWorkTime';
import { getReasonBranchInfoSelector } from '../../../../../redux/selectors';

type CalendarMonthType = {
  selectedDate?: string;
  setSelectedDate: (value: string) => void;
  // setDesiredDate: () => void
  setDesiredDate?: (value: boolean) => void;
  onPrevWeek: () => void;
  onNextWeek: () => void;
  control: any;
  name: string;
  setSelectedDesiredDateCallback?: (value: string) => void;
  valueDateTime?: Date; //
  setValueDateTime?: (value: Date) => void;
  valueTime?: string;
  setTimeDefaultValue?: (value: { value: string; label: string }) => void;
  timeDefaultValue?: { value: string; label: string } | null;
  setChoicesTimeCallback?: (value: Array<{ value: string; label: string }>) => void;
  choicesTime?: Array<{ value: string; label: string }>;
  setSelectedTime?: (value: { value: string; label: string }) => void;
};

export type FormDataModalType = {
  time: string;
};

export const CalendarDesiredDate: FC<CalendarMonthType> = ({
  setSelectedDate,
  onPrevWeek,
  onNextWeek,
  control,
  name,
  setDesiredDate,
  setSelectedDesiredDateCallback,
  valueDateTime,
  setValueDateTime,
  valueTime,
  setTimeDefaultValue,
  timeDefaultValue,
  selectedDate,
  setChoicesTimeCallback,
  choicesTime,
  setSelectedTime,
}) => {
  const branchInfo = useSelector(getReasonBranchInfoSelector);

  //holidays handling
  const setTimeHoliday = (start: string, end: string): number => {
    const editStart = Number(start.slice(0, 2));
    const editEnd = Number(end.slice(0, 2));
    return editEnd - editStart;
  };

  const fullHolidayOfficial = branchInfo.official.filter(
    (priv) => setTimeHoliday(priv.start_time, priv.end_time) > 8,
  );

  //
  const currentStartTimeWork =
    branchInfo.workschedule[dayjs(selectedDate).day() - 1] &&
    branchInfo.workschedule[dayjs(selectedDate).day() - 1].start_time_work;
  const currentEndTimeWork =
    branchInfo.workschedule[dayjs(selectedDate).day() - 1] &&
    branchInfo.workschedule[dayjs(selectedDate).day() - 1].end_time_work;

  const fullHolidayPrivate =
    currentEndTimeWork &&
    currentStartTimeWork &&
    branchInfo.private.filter(
      (priv) =>
        Number(priv.start_time.slice(0, 2)) <= Number(currentStartTimeWork.slice(0, 2)) &&
        Number(priv.end_time.slice(0, 2)) >= Number(currentEndTimeWork.slice(0, 2)),
    );
  //

  const notFullHolidayPrivate = branchInfo.private.filter(
    (priv) => setTimeHoliday(priv.start_time, priv.end_time) <= 24,
  );
  const notFullHolidayOfficial = branchInfo.official.filter(
    (priv) => setTimeHoliday(priv.start_time, priv.end_time) <= 24,
  );

  //sync calendar desired date and available date
  const valueTimeFromAvailable = choicesTime?.find(
    (time) => time.label === valueTime?.slice(11, 16),
  );
  const selectedValueTime = choicesTime?.find((time) => time.label === selectedDate?.slice(11, 16));

  useEffect(() => {
    if (valueTimeFromAvailable) {
      valueTimeFromAvailable && setTimeDefaultValue && setTimeDefaultValue(valueTimeFromAvailable);
    }
    if (selectedValueTime) {
      selectedValueTime && setTimeDefaultValue && setTimeDefaultValue(selectedValueTime);
      setSelectedTime && setSelectedTime(selectedValueTime);
    }
  }, [valueTimeFromAvailable, selectedValueTime]);

  useEffect(() => {
    if (selectedDate) {
      setSelectedDate(dayjs(selectedDate).format('YYYY-MM-DD'));
    }

    if (branchInfo && selectedDate && setChoicesTimeCallback) {
      filterWorkTime(
        branchInfo.workschedule,
        new Date(selectedDate).getDay() - 1,
        dayjs(selectedDate).format('YYYY-MM-DD'),
        notFullHolidayPrivate,
        notFullHolidayOfficial,
        setChoicesTimeCallback,
      );
    }
  }, []);

  //
  const isDayDisabled = (activeStartDate: Date, date: Date, /*view: Detail*/): boolean => {
    const holidayPrivate =
      fullHolidayPrivate &&
      fullHolidayPrivate.some((day) => day.date === dayjs(date).format('YYYY-MM-DD'));
    const holidayOfficial = fullHolidayOfficial.some(
      (day) => day.date === dayjs(date).format('YYYY-MM-DD'),
    );

    const formatDayOfWeek = branchInfo.workschedule.map((day) =>
      day.day_of_week !== 7 ? { ...day, day_of_week: day.day_of_week + 1 } : day,
    );
    const formatDayOfWeekFinally = formatDayOfWeek.map((day) =>
      day.day_of_week === 7 ? { ...day, day_of_week: 0 } : day,
    );
    const workDay = formatDayOfWeekFinally.filter((day) => day.is_weekend);

    const weekEnd = workDay.some((day) => day.day_of_week === date.getDay());

    return (
      date.getTime() < new Date().getTime() - 86400000 ||
      holidayPrivate ||
      holidayOfficial ||
      weekEnd
    );
  };

  const isDayWorked = (date: Date): boolean => {
    const holidayPrivate = branchInfo.private.some(
      (day) => day.date === dayjs(date).format('YYYY-MM-DD'),
    );
    const holidayOfficial = branchInfo.official.some(
      (day) => day.date === dayjs(date).format('YYYY-MM-DD'),
    );

    const formatDayOfWeek = branchInfo.workschedule.map((day) =>
      day.day_of_week !== 7 ? { ...day, day_of_week: day.day_of_week + 1 } : day,
    );
    const formatDayOfWeekFinally = formatDayOfWeek.map((day) =>
      day.day_of_week === 7 ? { ...day, day_of_week: 0 } : day,
    );
    const workDay = formatDayOfWeekFinally.filter((day) => day.is_weekend);

    const weekEnd = workDay.some((day) => day.day_of_week === date.getDay());

    return holidayPrivate || holidayOfficial || weekEnd;
  };

  const onSetDate = (date: Date): void => {
    setSelectedDate(dayjs(date).format('YYYY-MM-DD'));
    if (branchInfo && setChoicesTimeCallback) {
      filterWorkTime(
        branchInfo.workschedule,
        date.getDay() - 1,
        dayjs(date).format('YYYY-MM-DD'),
        notFullHolidayPrivate,
        notFullHolidayOfficial,
        setChoicesTimeCallback,
      );
    }
  };

  const setDesiredDateHandler = (value: boolean): void => {
    setDesiredDate && setDesiredDate(value);
  };

  return (
    <>
      <div className="calendar__info">
        <div className="calendar__info__calendar-general">
          <CalendarGeneral
            setDate={onSetDate}
            onPrevClick={() => {
              onPrevWeek();
            }}
            onNextClick={() => {
              onNextWeek();
            }}
            disabled={true}
            disabledCallback={isDayDisabled}
            valueDateTime={valueDateTime}
            isDayWorked={isDayWorked}
            setValueDateTime={setValueDateTime}
            selectedDate={selectedDate}
            isCalendar={true}
          />

          <div className="calendar__info__calendar-general__time-block">
            <div className="calendar__info__calendar-general__time-block_label-select">
              Bitte wählen Sie die Zeit aus, zu der Sie kommen möchten:
            </div>
            <div>
              <ReactSelect
                onClick={setDesiredDateHandler}
                onChange={setDesiredDateHandler}
                onChangeInput={setSelectedDesiredDateCallback}
                options={choicesTime}
                label={'Start time'}
                placeholder={'Start time'}
                isMulti={false}
                defaultValue={
                  timeDefaultValue && timeDefaultValue.value !== '' ? timeDefaultValue : null
                }
                control={control}
                status="time"
                name={name}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};