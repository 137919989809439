import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const registrationAPI = {
  getClientWeek(
    branchId: number | null,
    reasonId: number,
    doctorId: number | undefined,
    week: number,
  ) {
    return instance.get<any>(
      `/api/client/events/free/times/?branch=${branchId}&reason=${reasonId}&doctor=${doctorId}&week=${week}`,
      {},
    );
  },

  getClientWeekFirstRender(branchId: number | null, reasonId: number, doctorId: number) {
    return instance.get<DataOfClientsType>(
      `/api/client/events/free/times/?branch=${branchId}&reason=${reasonId}&doctor=${doctorId}`,
      {},
    );
  },

  createClientEvent(data: any, branchId: number | null) {
    return instance.post<any>(`/api/client/events/create/?branch=${branchId}`, data, {});
  },

  getClientBranchInfo(branchId: number | null) {
    return instance.get<DateInfoOfClientType>(`/api/branches/${branchId}/info/`, {});
  },

  getDoctorInfo(branchId: number | null, reasonId: number) {
    return instance.get<DoctorsType>(`/api/branch/${branchId}/reasons/${reasonId}/`, {});
  },
};

//types
export type RegistrationType = {
  start: string;
  client: string;
  status: string;
  insurance_type: string;
  branch: string;
  reason: any;
  gender: string;
  firs_name: string;
  last_name: string;
  date_birth: any;
  email: string;
  phone: string;
  message: string;
  unfinished: string;
  email_reminded: string;
  sms_reminded: string;
};
export type TimeSlotsType = {
  start: string;
  end: string;
  disable: string;
  full_date: string;
};
export type DatesSlotsType = {
  day_name: string;
  date: string;
  time_slots: Array<TimeSlotsType>;
};
export type DataOfClientsType = {
  first_last_date_week: string;
  next_week: number;
  previous_week: number;
  dates_slots: Array<DatesSlotsType>;
  empty_slots: boolean;
};

export type HolidayType = {
  branches: Array<number>;
  date: string;
  end_time: string;
  id: number;
  name: string;
  official: boolean;
  owner: number;
  owner_display: string;
  start_time: string;
};

export type ReasonsInfoType = {
  branches: Array<number>;
  color: string;
  doctor: number; //delete
  doctor_display: string; //delete
  doctor_email: string; //delete
  doctor_phone: string; //delete
  doctor_photo: string; //delete
  id: number;
  limit_time: number;
  offset_time: number;
  offset_time_display: string;
  order: number;
  title: string;

  doctors: []; //add
};

export type WorkScheduleInfoType = {
  branch: number;
  branch_display: string;
  day_of_week: number;
  day_of_week_display: string;
  end_lunch_break: string;
  end_time_work: string;
  id: number;
  is_weekend: boolean;
  lunch_break: boolean;
  start_lunch_break: string;
  start_time_work: string;
};

export type DateInfoOfClientType = {
  official: Array<HolidayType>;
  private: Array<HolidayType>;
  reasons: Array<ReasonsInfoType>;
  workschedule: Array<WorkScheduleInfoType>;
  desired_date: boolean;
  email: string;
  free_date: boolean;
  name: string;
  phone: string;
  show_title: boolean;
  sity: string;
  street: string;
  street_number: string;
  whatsapp: string;
  zip_code: string;
};

export type DoctorType = {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  owner: number;
  owner_display: string;
  phone: string;
  photo: string;
  role: string;
};

export type DoctorsType = {
  doctors: Array<DoctorType>;
};