import { SubmitHandler, useForm } from 'react-hook-form';
import './Step2.scss';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { createClientEventTC, setPrivacyPolicyAC } from '../../../redux/registration-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { newOption } from '../../../components/Widgets/Select/ReactSelectStyles';
import { useErrorHandler } from '../../../utils/useErrorHandler';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getChosenDaySelector,
  getErrorStateSelector,
  getFieldsSelector,
  getRegistrationDataSelector,
  getSelectedDoctorSelector,
  getStatusSelector,
} from '../../../redux/selectors';
import { baseURL_API, baseURL_WIDGET } from '../../../utils/BaseURL';
import { setAppStatusAC } from '../../../redux/app-reducer';
import { VisitInfo } from './VistiInfo/VisitInfo';
import { FormStep2 } from './FormStep2/FormStep2';
import dayjs from 'dayjs';

export type FormDataType = {
  gender: { value: any; label: any };
  date_birth: any;
  first_name: string;
  last_name: string;
  email: string;
  message: string;
  phone: string;
  doctor: number;
};

type Step2Type = {
  setCurrentPage: (value: string) => void;
  successPage: string;
  step1: string;
};

export const Step2: React.FC<Step2Type> = memo(({ setCurrentPage, successPage }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const registrationData = useSelector(getRegistrationDataSelector);
  const fields = useSelector(getFieldsSelector);
  const errorState = useSelector(getErrorStateSelector);
  const status = useSelector(getStatusSelector);
  const chosenDay = useSelector(getChosenDaySelector);
  const selectedDoctor = useSelector(getSelectedDoctorSelector);

  const [sendData, setSendData] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);

  const photoDoctorUrl =
    selectedDoctor && selectedDoctor.photo
      ? `${baseURL_API}${selectedDoctor.photo}`
      : `${baseURL_WIDGET}static/media/doctor.b6f41246e0278d64f1f48640d71f6144.svg`;

  //visit information
  const dateVisit = registrationData.selectedDate && registrationData.selectedDate.slice(0, 10);
  const timeVisit =
    registrationData.selectedDesiredTime.label && registrationData.selectedDesiredTime.label;

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const formatDate = (date: any): any => {
    return {
      dayOfWeek: new Date(date).getDay(),
      date: new Date(date).getDate(),
    };
  };

  const dayOfWeekDisplay = daysOfWeek.find((day, index) =>
    index !== 6
      ? index + 1 === formatDate(dateVisit).dayOfWeek
      : index === formatDate(dateVisit).dayOfWeek + 6,
  );

  const dateVisitDisplay = `${dayOfWeekDisplay} ${formatDate(dateVisit).date}, ${timeVisit}`;
  const chosenDateEdit = `${chosenDay.date.slice(0, 10)}T${chosenDay.time}`;
  const dateDefaultValue = chosenDay ? chosenDateEdit : fields.date_birth.initial_value;

  // error handling
  const formDataArray = ['gender', 'date_birth', 'first_name', 'last_name', 'email', 'phone'];

  const genderErrorHandler = useErrorHandler('gender', errorState, formDataArray);
  const birthDateErrorHandler = useErrorHandler('date_birth', errorState, formDataArray);
  const firstNameErrorHandler = useErrorHandler('first_name', errorState, formDataArray);
  const lastNameErrorHandler = useErrorHandler('last_name', errorState, formDataArray);
  const emailNameErrorHandler = useErrorHandler('email', errorState, formDataArray);
  const phoneNameErrorHandler = useErrorHandler('phone', errorState, formDataArray);

  // for select
  const choicesGender = Object.entries(fields.gender.choices).map((br) => br);
  const valueOptionGender =
    fields && Object.entries(fields.gender.choices).map((b: any) => newOption(b[0], b[1]));
  const labelOptionGender =
    registrationData.selectedGender.value && registrationData.selectedGender.toString();

  const defaultValueGender = registrationData && registrationData.selectedGender;

  // default value
  const step2Data = registrationData && {
    gender: defaultValueGender && defaultValueGender,
    date_birth: registrationData.selectedBirthDate,
    first_name: registrationData.selectedFirstName,
    last_name: registrationData.selectedLastName,
    email: registrationData.selectedEmail,
    comment: registrationData.selectedComment,
    phone: registrationData.selectedPhone,
    start: `${registrationData.selectedDate} ${registrationData.selectedTimeDesiredDate.value}`,
  };

  const { register, handleSubmit, reset, control } = useForm<FormDataType>({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      if (step2Data) {
        return step2Data;
      }
    }, [step2Data]),
  });

  useEffect(() => {
    if (!registrationData.selectedDate) {
      navigate(`${location.pathname}${location.search}`);
    }
  }, []);

  useEffect(() => {
    if (registrationData.isPrivacyPolicy) {
      setChecked(!checked);
    }
  }, []);

  useEffect(() => {
    if (step2Data) {
      reset(step2Data);
    }
  }, []);

  useEffect(() => {
    if (status === 'failed') {
      genderErrorHandler.setErrorCallback();
      birthDateErrorHandler.setErrorCallback();
      firstNameErrorHandler.setErrorCallback();
      lastNameErrorHandler.setErrorCallback();
      emailNameErrorHandler.setErrorCallback();
      phoneNameErrorHandler.setErrorCallback();
    }
  }, [status]);

  const changeChecked = (): void => {
    setChecked(!checked);
    dispatch(setPrivacyPolicyAC(!checked));
  };

  const onSubmit: SubmitHandler<FormDataType> = (data) => {
    const start =
      registrationData.selectedDate && registrationData.selectedDate.length < 16
        ? `${registrationData.selectedDate} ${registrationData.selectedTimeDesiredDate.value}`
        : registrationData.selectedDate;

    const newData = {
      gender: data.gender.value,
      date_birth: dayjs(data.date_birth).format("YYYY-MM-DD"),
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      message: data.message,
      phone: data.phone,
      start: start,
      insurance_type: registrationData.selectedInsuranceType,
      reason: registrationData.selectedReason,
      desired_date: registrationData.desired_date,
      doctor: selectedDoctor?.id,
    };
    dispatch(createClientEventTC(newData, registrationData.selectedBranch));
    setSendData(true);
  };

  if (sendData && status === 'succeeded') {
    setCurrentPage(successPage);
  }

  return (
    <div
      className="online-dates"
      onClick={() => {
        dispatch(setAppStatusAC('idle'));
      }}
    >
      {/*<div className="header"></div>*/}
      <div className="content">
        <VisitInfo
          photoDoctorUrl={photoDoctorUrl}
          registrationData={registrationData}
          doctorInfo={selectedDoctor}
          dateVisitDisplay={dateVisitDisplay}
        />

        <FormStep2
          fields={fields}
          status={status}
          errorState={errorState}
          registrationData={registrationData}
          birthDateErrorHandler={birthDateErrorHandler}
          genderErrorHandler={genderErrorHandler}
          emailNameErrorHandler={emailNameErrorHandler}
          firstNameErrorHandler={firstNameErrorHandler}
          lastNameErrorHandler={lastNameErrorHandler}
          phoneNameErrorHandler={phoneNameErrorHandler}
          dateDefaultValue={dateDefaultValue}
          defaultValueGender={defaultValueGender}
          choicesGender={choicesGender}
          labelOptionGender={labelOptionGender}
          valueOptionGender={valueOptionGender}
          checked={checked}
          changeChecked={changeChecked}
          handleSubmit={handleSubmit}
          register={register}
          onSubmit={onSubmit}
          control={control}
          reset={reset}
        />
      </div>
    </div>
  );
});
