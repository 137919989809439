import './Preloader.scss';
import { baseURL_WIDGET } from '../../utils/BaseURL';

const Preloader: React.FC = () => {
  return (
    <div className="preloader">
      <img
        alt="preloader"
        src={`${baseURL_WIDGET}static/media/preloader.d3fbaf2b81fd92c49692.gif`}
        className="preloader-img"
      />
    </div>
  );
};

export default Preloader;