import React, {FC, useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import Select from "react-select";
import {
    customStyles,
    customStylesError,
    customStylesMulti,
    customStylesTime
} from "./ReactSelectStyles";

type ReactSelectType = {
    error?: string | null
    help_text?: string | null
    label?: string
    placeholder?: string
    control: any
    onClick?: (value: boolean) => void
    onClickError?: () => void
    onChange?: (value: boolean) => void
    isMulti: boolean
    options: any
    name: string
    defaultValue?: { value: any, label: any } | null
    onChangeInput?: (value: string) => void
    onChangeInputObject?: (value: { value: string, label: string }) => void
    status?: "time"
}

export const ReactSelect: FC<ReactSelectType> = (
    {
        control, error, placeholder, defaultValue,
        isMulti, options, help_text, name,
        onChange, onChangeInput,
        onClickError, onChangeInputObject, status
    }) => {

    const [focusInput, setFocusInput] = useState<boolean>(false)
    const [localValue, setLocalValue] = useState<boolean>(false)

    useEffect(() => {
        if (localValue) {
            onChange && onChange(true)
        } else {
            onChange && onChange(false)
        }
    }, [localValue])

    const changeLabelStatusOn = (value: any): void => {
        if (value) {
            setLocalValue(true)
        } else {
            setLocalValue(false)
        }

        setFocusInput(true)
        onChange && onChange(true)
    }
    const changeLabelStatusOff = (value: any): void => {
        if (value) {
            setLocalValue(true)
        } else {
            setLocalValue(false)
        }

        setLocalValue(false)
        setFocusInput(false)
        onChange && onChange(false)
    }

    const onChangeInputHandler = (value: any): void => {
        if (value) {
            setLocalValue(true)
        } else {
            setLocalValue(false)
        }

        setFocusInput(true)
        onChangeInput && onChangeInput(value)
        onChangeInputObject && onChangeInputObject({value, label: value})
    }

    const onClickErrorHandler = (): void => {
        onClickError && onClickError()
    }

    const styleSelect = status === "time" ?
        customStylesTime
        : error
            ? customStylesError
            : isMulti
                ? customStylesMulti
                : customStyles

    return (
        <div style={{display: "flex", flexDirection: "column", marginBottom: "15px"}}>
            <div
                onClick={onClickErrorHandler}
            >
                <Controller
                    name={name}
                    control={control}
                    render={({field}) => <Select
                        {...field}
                        name={field.name}
                        styles={styleSelect}
                        placeholder={isMulti ? placeholder : ''}
                        isMulti={isMulti}
                        onInputChange={() => {onChangeInputHandler(field.value)}}
                        onFocus={changeLabelStatusOn}
                        isDisabled={options.length === 0}
                        autoFocus={field.value.value !== ''}
                        onBlur={field.value.value ? changeLabelStatusOn : changeLabelStatusOff}
                        options={options}
                    />}
                />

                {!isMulti &&
                    <span style={{
                        position: "relative",
                        top: `${focusInput || defaultValue ? "-58px" : "-45px"}`,
                        left: "18px",
                        width: "1%",
                        color: `${error ? "#c30052" : "#737484"}`,
                        fontSize: `${focusInput || defaultValue ? "14px" : "16px"}`,
                        margin: "8px",
                        marginTop: "12px",
                        letterSpacing: `${focusInput || defaultValue ? "0.4px" : "0.75px"}`,
                        transition: "0s",
                    }}>
                    {placeholder}
                </span>
                }
            </div>

            <div style={{width: "50%",}}>
                <p style={{
                    color: "#c30052",
                    // marginTop: "3px",
                    marginTop: "-20px",
                    fontSize: "14px",
                    letterSpacing: "0.25px"
                }}>
                    {error && help_text}
                </p>
            </div>
        </div>
    )
};
